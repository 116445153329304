import axios from "axios";
import { server } from "../../server";

const getConfig = () => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  };
};

// Create purchase
export const createPurchaseAlimentation = (purchaseData) => async (dispatch) => {
  try {
    dispatch({ type: "CREATE_PURCHASE_ALIMENTATION_REQUEST" });

    const response = await axios.post(
      `${server}/purchase-alimentation/create-purchase`,
      purchaseData,
      getConfig()
    );

    if (response.data.success) {
      dispatch({
        type: "CREATE_PURCHASE_ALIMENTATION_SUCCESS",
        payload: response.data.purchase,
      });
      return response.data;
    }
  } catch (error) {
    dispatch({
      type: "CREATE_PURCHASE_ALIMENTATION_FAILURE",
      payload: error.response?.data?.message || "Error creating purchase",
    });
    throw error;
  }
};

// Fetch purchases by method (cash, bank, credit)
export const fetchPurchasesByMethod = (method, shopId) => async (dispatch) => {
  const actionTypes = {
    cash: ["FETCH_CASH_PURCHASES", "cashPurchases"],
    credit: ["FETCH_CREDIT_PURCHASES", "creditPurchases"],
  };

  const [actionType, stateKey] = actionTypes[method];

  try {
    dispatch({ type: `${actionType}_REQUEST` });

    const shop = typeof shopId === 'object' ? shopId._id : shopId;

    const url = method === 'credit'
      ? `${server}/purchase-alimentation/purchases/credit/${shop}`
      : `${server}/purchase-alimentation/purchases/${method}/${shop}`;

    const response = await axios.get(url, getConfig());

    if (response.data.success) {
      dispatch({
        type: `${actionType}_SUCCESS`,
        payload: response.data.purchases,
      });
      return response.data;
    }
  } catch (error) {
    dispatch({
      type: `${actionType}_FAILURE`,
      payload: error.response?.data?.message || `Error fetching ${method} purchases`,
    });
    throw error;
  }
};

// Pay remaining amount
export const payRemainingPurchase = (purchaseId, paymentData) => async (dispatch) => {
  try {
    dispatch({ type: "PAY_REMAINING_PURCHASE_REQUEST" });

    const response = await axios.post(
      `${server}/purchase-alimentation/pay-remaining/${purchaseId}`,
      paymentData,
      getConfig()
    );

    if (response.data.success) {
      dispatch({
        type: "PAY_REMAINING_PURCHASE_SUCCESS",
        payload: response.data.purchase,
      });
      return response.data;
    }
  } catch (error) {
    dispatch({
      type: "PAY_REMAINING_PURCHASE_FAILURE",
      payload: error.response?.data?.message || "Error processing payment",
    });
    throw error;
  }
};

// Add new action to fetch clients with debt
export const fetchClientsWithDebt = (shopId) => async (dispatch) => {
  try {
    if (!shopId) {
      throw new Error('Shop ID is required');
    }

    dispatch({ type: "FETCH_CLIENTS_WITH_DEBT_REQUEST" });  // Add loading state

    const response = await axios.get(
      `${server}/purchase-alimentation/clients-with-debt/${shopId}`,
      getConfig()
    );

    dispatch({
      type: "FETCH_CLIENTS_WITH_DEBT_SUCCESS",
      payload: response.data.clients
    });

    return response.data.clients;
  } catch (error) {
    dispatch({
      type: "FETCH_CLIENTS_WITH_DEBT_FAILURE",
      payload: error.message
    });
    throw error;
  }
};

// Add delete purchase action
export const deletePurchaseAlimentation = (purchaseId) => async (dispatch) => {
  try {
    dispatch({ type: "DELETE_PURCHASE_REQUEST" });

    const response = await axios.delete(
      `${server}/purchase-alimentation/delete/${purchaseId}`,
      getConfig()
    );

    if (response.data.success) {
      dispatch({
        type: "DELETE_PURCHASE_SUCCESS",
        payload: purchaseId
      });
      return response.data;
    }
  } catch (error) {
    dispatch({
      type: "DELETE_PURCHASE_FAILURE",
      payload: error.response?.data?.message || "Error deleting purchase"
    });
    throw error;
  }
};
// Fetch daily price_achat totals for purchases
export const fetchDailyPurchasePriceAchat = (shopId) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_DAILY_PURCHASE_PRICE_ACHAT_REQUEST" });

    const response = await axios.get(
      `${server}/purchase-alimentation/daily-price-achat/${shopId}`,
      getConfig()
    );

    dispatch({
      type: "FETCH_DAILY_PURCHASE_PRICE_ACHAT_SUCCESS",
      payload: response.data.totals,
    });

    return response.data;
  } catch (error) {
    dispatch({
      type: "FETCH_DAILY_PURCHASE_PRICE_ACHAT_FAILURE",
      payload:
        error.response?.data?.message || "Error fetching daily purchase totals",
    });
    throw error;
  }
};

// Fetch monthly price_achat totals for purchases
export const fetchMonthlyPurchasePriceAchat = (shopId) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_MONTHLY_PURCHASE_PRICE_ACHAT_REQUEST" });

    const response = await axios.get(
      `${server}/purchase-alimentation/monthly-price-achat/${shopId}`,
      getConfig()
    );

    dispatch({
      type: "FETCH_MONTHLY_PURCHASE_PRICE_ACHAT_SUCCESS",
      payload: response.data.totals,
    });

    return response.data;
  } catch (error) {
    dispatch({
      type: "FETCH_MONTHLY_PURCHASE_PRICE_ACHAT_FAILURE",
      payload:
        error.response?.data?.message || "Error fetching monthly purchase totals",
    });
    throw error;
  }
};

// Fetch daily sales totals for purchases
export const fetchDailyPurchaseSales = (shopId) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_DAILY_PURCHASE_SALES_REQUEST" });

    const response = await axios.get(
      `${server}/purchase-alimentation/daily-sales/${shopId}`,
      getConfig()
    );

    dispatch({
      type: "FETCH_DAILY_PURCHASE_SALES_SUCCESS",
      payload: response.data.totals,
    });

    return response.data;
  } catch (error) {
    dispatch({
      type: "FETCH_DAILY_PURCHASE_SALES_FAILURE",
      payload:
        error.response?.data?.message || "Error fetching daily purchase totals",
    });
    throw error;
  }
};

// Fetch monthly price_achat totals for purchases
export const fetchMonthlyPurchaseSales = (shopId) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_MONTHLY_PURCHASE_SALES_REQUEST" });

    const response = await axios.get(
      `${server}/purchase-alimentation/monthly-sales/${shopId}`,
      getConfig()
    );

    dispatch({
      type: "FETCH_MONTHLY_PURCHASE_SALES_SUCCESS",
      payload: response.data.totals,
    });

    return response.data;
  } catch (error) {
    dispatch({
      type: "FETCH_MONTHLY_PURCHASE_SALES_FAILURE",
      payload:
        error.response?.data?.message || "Error fetching monthly purchase totals",
    });
    throw error;
  }
};

