import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  transactions: [],
  summaries: {
    dailyTotal: 0,
    monthlyTotal: 0
  },
  loading: false,
  error: null
};

export const retraitDepotReducer = createReducer(initialState, (builder) => {
  builder
    // Create Transaction
    .addCase("CreateTransactionRequest", (state) => {
      state.loading = true;
    })
    .addCase("CreateTransactionSuccess", (state, action) => {
      state.loading = false;
      state.transactions.unshift(action.payload);
      state.error = null;
    })
    .addCase("CreateTransactionFailure", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })

    // Fetch Transactions
    .addCase("FetchTransactionsRequest", (state) => {
      state.loading = true;
    })
    .addCase("FetchTransactionsSuccess", (state, action) => {
      state.loading = false;
      state.transactions = action.payload.transactions;
      state.summaries = action.payload.summaries;
      state.error = null;
    })
    .addCase("FetchTransactionsFailure", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })

    // Update Transaction
    .addCase("UpdateTransactionRequest", (state) => {
      state.loading = true;
    })
    .addCase("UpdateTransactionSuccess", (state, action) => {
      state.loading = false;
      state.transactions = state.transactions.map(transaction =>
        transaction._id === action.payload._id ? action.payload : transaction
      );
      state.error = null;
    })
    .addCase("UpdateTransactionFailure", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })

    // Delete Transaction
    .addCase("DeleteTransactionRequest", (state) => {
      state.loading = true;
    })
    .addCase("DeleteTransactionSuccess", (state, action) => {
      state.loading = false;
      state.transactions = state.transactions.filter(
        transaction => transaction._id !== action.payload
      );
      state.error = null;
    })
    .addCase("DeleteTransactionFailure", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
});