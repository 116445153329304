import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const PayRemainingDialog = ({ open, onClose, purchase, onPayment, user }) => {
  const [amount, setAmount] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('cash');
  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      if (amount > purchase.total_amount) {
        toast.error("Payment amount cannot exceed remaining amount");
        return;
      }
      await onPayment({
        amount_paid: Number(amount),
        payment_method: paymentMethod,
        user: user._id
      });
      toast.success("Paiement traité avec succès");
      navigate('/transaction-alimentation/history');
      setAmount('');
      setPaymentMethod('cash');
    } catch (error) {
      toast.error(error.response?.data?.message || "Une erreur s'est produite lors du traitement du paiement");
      console.error('Payment error:', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Payer le reste du montant</DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <TextField
            fullWidth
            label="Montant à payer"
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            sx={{ mb: 2 }}
          />
          <FormControl fullWidth>
            <InputLabel>Mode de paiement</InputLabel>
            <Select
              value={paymentMethod}
              onChange={(e) => setPaymentMethod(e.target.value)}
              label="Mode de paiement"
            >
              <MenuItem value="cash">Cash</MenuItem>
              <MenuItem value="bank">Bank</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='secondary'>Annuler</Button>
        <Button
          onClick={handleSubmit}
          disabled={!amount || Number(amount) <= 0}
          variant="contained"
          color='secondary'
        >
          Payer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PayRemainingDialog; 