import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Tabs,
  Tab,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  TextField,
  Card,
  CardContent,
  Grid,
} from '@mui/material';
import { VisibilityOutlined, MonetizationOn, History, Print, Delete as DeleteIcon } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPurchasesByMethod, payRemainingPurchase, deletePurchaseAlimentation } from '../../redux/actions/purchaseAlimentation';
import ProductDetailsDialog from '../../components/ProductDetailsDialog';
import PayRemainingDialog from '../../components/PayRemainingDialog';
import PaymentHistoryDialog from '../../components/PaymentHistoryDialog';
import { generateReceipt } from '../../utils/receiptGenerator';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useInvoiceNumber } from '../../hooks/useInvoiceNumber';

const TabPanel = ({ children, value, index }) => (
  <div role="tabpanel" hidden={value !== index}>
    {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
  </div>
);

const AlimentationPurchaseHistory = () => {
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = useState(0);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const { user } = useSelector((state) => state.user);
  const { cashPurchases, creditPurchases, loading } = useSelector(
    (state) => state.purchaseAlimentation
  );
  const [selectedShop, setSelectedShop] = useState(
    user?.role === 'superadmin' ? 'all' : user?.shop?._id
  );
  const [selectedPurchase, setSelectedPurchase] = useState(null);
  const [openPayDialog, setOpenPayDialog] = useState(false);
  const [openHistoryDialog, setOpenHistoryDialog] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const { getInvoiceNumber } = useInvoiceNumber();

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(fetchPurchasesByMethod('cash', selectedShop));
        await dispatch(fetchPurchasesByMethod('credit', selectedShop));
      } catch (error) {
        console.error('Error fetching purchases:', error);
      }
    };
    fetchData();
  }, [dispatch, selectedShop]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleShopChange = (event) => {
    setSelectedShop(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filterSalesByDate = (sales) => {
    return sales.filter((sale) => {
      if (!startDate && !endDate) return true;

      const saleDate = moment(sale.createdAt).startOf('day');

      if (startDate && !endDate) {
        return saleDate.isSameOrAfter(moment(startDate).startOf('day'));
      }

      if (!startDate && endDate) {
        return saleDate.isSameOrBefore(moment(endDate).startOf('day'));
      }

      return saleDate.isBetween(
        moment(startDate).startOf('day'),
        moment(endDate).startOf('day'),
        'day',
        '[]'
      );
    });
  };

  const filterPurchases = (purchases) => {
    const filteredBySearch = purchases.filter((purchase) =>
      purchase.client_name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    return filterSalesByDate(filteredBySearch);
  };

  const handleViewProducts = (products) => {
    setSelectedProducts(products);
    setOpenDialog(true);
  };

  const handlePayRemaining = async (paymentData) => {
    try {
      await dispatch(payRemainingPurchase(selectedPurchase._id, paymentData));
      setOpenPayDialog(false);
      dispatch(fetchPurchasesByMethod('cash', selectedShop));
      dispatch(fetchPurchasesByMethod('credit', selectedShop));
    } catch (error) {
      console.error('Error processing payment:', error);
    }
  };

  const handlePrint = async (purchase) => {
    try {
      const shopId = purchase.shop?._id || purchase.shop;
      const invoiceNumber = await getInvoiceNumber(shopId);
      const doc = await generateReceipt(purchase, invoiceNumber);
      doc.save(`facture-${invoiceNumber}.pdf`);
    } catch (error) {
      toast.error("Erreur lors de l'impression de la facture");
      console.error('Print error:', error);
    }
  };

  const handleDelete = async (purchaseId) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer cet achat ?")) {
      try {
        await dispatch(deletePurchaseAlimentation(purchaseId));
        toast.success("Achat supprimé avec succès");
        // Refresh the purchases list
        await dispatch(fetchPurchasesByMethod('cash', selectedShop));
        await dispatch(fetchPurchasesByMethod('credit', selectedShop));
      } catch (error) {
        toast.error(error.message || "Erreur lors de la suppression");
      }
    }
  };

  const renderPurchaseTable = (purchases) => (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Client</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell>Montant Total</TableCell>
            <TableCell>Montant payé</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Effectue par</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filterPurchases(purchases).map((purchase) => (
            <TableRow key={purchase._id}>
              <TableCell>
                {new Date(purchase.createdAt).toLocaleString('fr-FR', {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit'
                })}
              </TableCell>
              <TableCell>{purchase.client_name}</TableCell>
              <TableCell>{purchase.client_phone}</TableCell>
              <TableCell>{purchase.total_amount} Fbu</TableCell>
              <TableCell>{purchase.amount_paid} Fbu</TableCell>
              <TableCell>
                <Typography
                  color={
                    purchase.payment_status === 'paid'
                      ? 'success.main'
                      : purchase.payment_status === 'partial'
                        ? 'warning.main'
                        : 'error.main'
                  }
                >
                  {purchase.payment_status.toUpperCase()}
                </Typography>
              </TableCell>
              <TableCell>{purchase.user?.name}</TableCell>
              <TableCell>
                <Box display="flex" gap={1}>
                  <IconButton
                    onClick={() => handleViewProducts(purchase.products)}
                  >
                    <VisibilityOutlined />
                  </IconButton>
                  {purchase.payment_status !== 'paid' && (
                    <IconButton
                      onClick={() => {
                        setSelectedPurchase(purchase);
                        setOpenPayDialog(true);
                      }}
                      color="secondary"
                    >
                      <MonetizationOn />
                    </IconButton>
                  )}
                  <IconButton
                    onClick={() => {
                      setSelectedPurchase(purchase);
                      setOpenHistoryDialog(true);
                    }}
                    color="info"
                  >
                    <History />
                  </IconButton>
                  <IconButton
                    onClick={() => handlePrint(purchase)}
                  >
                    <Print />
                  </IconButton>
                  {user.role === 'superadmin' && (
                    <IconButton
                      onClick={() => handleDelete(purchase._id)}
                      color="error"
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
  const renderPurchaseCreditTable = (purchases) => (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Client</TableCell>
            <TableCell>Telephone</TableCell>
            <TableCell>Montant Total</TableCell>
            <TableCell>Montant payé</TableCell>
            <TableCell>Montant restant</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Effectue par</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filterPurchases(purchases).map((purchase) => (
            <TableRow key={purchase._id}>
              <TableCell>
                {new Date(purchase.createdAt).toLocaleString('fr-FR', {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit'
                })}
              </TableCell>
              <TableCell>{purchase.client_name}</TableCell>
              <TableCell>{purchase.client_phone}</TableCell>
              <TableCell>{purchase.total_amount} Fbu</TableCell>
              <TableCell>{purchase.amount_paid} Fbu</TableCell>
              <TableCell>{purchase.remaining_amount} Fbu</TableCell>
              <TableCell>
                <Typography
                  color={
                    purchase.payment_status === 'paid'
                      ? 'success.main'
                      : purchase.payment_status === 'partial'
                        ? 'warning.main'
                        : 'error.main'
                  }
                >
                  {purchase.payment_status.toUpperCase()}
                </Typography>
              </TableCell>
              <TableCell>{purchase.user?.name}</TableCell>
              <TableCell>
                <Box display="flex" gap={1}>
                  <IconButton
                    onClick={() => handleViewProducts(purchase.products)}
                  >
                    <VisibilityOutlined />
                  </IconButton>
                  {purchase.payment_status !== 'paid' && (
                    <IconButton
                      onClick={() => {
                        setSelectedPurchase(purchase);
                        setOpenPayDialog(true);
                      }}
                      color="secondary"
                    >
                      <MonetizationOn />
                    </IconButton>
                  )}
                  <IconButton
                    onClick={() => {
                      setSelectedPurchase(purchase);
                      setOpenHistoryDialog(true);
                    }}
                    color="info"
                  >
                    <History />
                  </IconButton>
                  <IconButton
                    onClick={() => handlePrint(purchase)}
                  >
                    <Print />
                  </IconButton>
                  {user.role === 'superadmin' && (
                    <IconButton
                      onClick={() => handleDelete(purchase._id)}
                      color="error"
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const calculateTotalCash = () => {
    const filteredCashPurchases = filterSalesByDate(cashPurchases);
    return filteredCashPurchases.reduce((total, purchase) => total + purchase.total_amount, 0) || 0;
  };

  const calculateCreditStats = () => {
    const filteredCreditPurchases = filterSalesByDate(creditPurchases);
    return filteredCreditPurchases.reduce((acc, purchase) => ({
      total: acc.total + purchase.total_amount,
      paid: acc.paid + purchase.amount_paid,
      remaining: acc.remaining + purchase.remaining_amount
    }), { total: 0, paid: 0, remaining: 0 });
  };

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4">Historique des achats d'alimentation</Typography>
      </Box>

      {tabValue === 0 ? (
        <Card sx={{ mb: 3, mt: 2 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Total des achats en cash
            </Typography>
            <Typography variant="h4">
              {calculateTotalCash().toLocaleString()} Fbu
            </Typography>
          </CardContent>
        </Card>
      ) : (
        <Grid container spacing={3} sx={{ mb: 3, mt: 1 }}>
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Total des achats à crédit
                </Typography>
                <Typography variant="h4">
                  {calculateCreditStats().total.toLocaleString()} Fbu
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h6" color="success.main" gutterBottom>
                  Total payé
                </Typography>
                <Typography variant="h4" color="success.main">
                  {calculateCreditStats().paid.toLocaleString()} Fbu
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h6" color="error.main" gutterBottom>
                  Total restant
                </Typography>
                <Typography variant="h4" color="error.main">
                  {calculateCreditStats().remaining.toLocaleString()} Fbu
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}

      <Box display="flex" gap={2} mb={2}>
        <TextField
          placeholder="Rechercher par nom de client"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={handleSearchChange}
          sx={{ flexGrow: 1 }}
        />

        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label="Date de début"
            value={startDate}
            onChange={(newValue) => setStartDate(newValue)}
            slotProps={{ textField: { size: 'small' } }}
          />
          <DatePicker
            label="Date de fin"
            value={endDate}
            onChange={(newValue) => setEndDate(newValue)}
            slotProps={{ textField: { size: 'small' } }}
          />
        </LocalizationProvider>
      </Box>

      <Box sx={{
        borderBottom: 1,
        borderColor: 'divider',
        backgroundColor: 'white',
        borderRadius: '4px 4px 0 0',
        mb: 2
      }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          sx={{
            '& .MuiTab-root': {
              color: 'grey.700',
              '&.Mui-selected': {
                color: 'primary.main',
              },
            },
          }}
        >
          <Tab label="Achats en Cash" />
          <Tab label="Achats à crédit" />
        </Tabs>
      </Box>

      <TabPanel value={tabValue} index={0}>
        {renderPurchaseTable(cashPurchases)}
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
        {renderPurchaseCreditTable(creditPurchases)}
      </TabPanel>

      <PayRemainingDialog
        open={openPayDialog}
        onClose={() => setOpenPayDialog(false)}
        purchase={selectedPurchase}
        onPayment={handlePayRemaining}
        user={user}
      />

      <PaymentHistoryDialog
        open={openHistoryDialog}
        onClose={() => setOpenHistoryDialog(false)}
        paymentHistory={selectedPurchase?.payment_history}
      />

      <ProductDetailsDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        products={selectedProducts}
      />
    </Box>
  );
};

export default AlimentationPurchaseHistory;
