import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  cashSales: [],
  bankSales: [],
  creditSales: [],
  dailyPriceAchat: 0,
  monthlyPriceAchat: 0,
  dailySales: 0,
  monthlySales: 0,
  loading: false,
  error: null,
  debtClients: [],
};

export const salesReducer = createReducer(initialState, {
  "FETCH_CASH_SALES_REQUEST": (state) => {
    state.loading = true;
    state.error = null;
  },
  "FETCH_CASH_SALES_SUCCESS": (state, action) => {
    state.loading = false;
    state.cashSales = action.payload.sales;
    state.error = null;
  },
  "FETCH_CASH_SALES_FAILURE": (state, action) => {
    state.loading = false;
    state.cashSales = [];
    state.error = action.payload;
  },

  "FETCH_BANK_SALES_REQUEST": (state) => {
    state.loading = true;
    state.error = null;
  },
  "FETCH_BANK_SALES_SUCCESS": (state, action) => {
    state.loading = false;
    state.bankSales = action.payload.sales;
    state.error = null;
  },
  "FETCH_BANK_SALES_FAILURE": (state, action) => {
    state.loading = false;
    state.bankSales = [];
    state.error = action.payload;
  },

  "FETCH_CREDIT_SALES_REQUEST": (state) => {
    state.loading = true;
    state.error = null;
  },
  "FETCH_CREDIT_SALES_SUCCESS": (state, action) => {
    state.loading = false;
    state.creditSales = action.payload.sales;
    state.error = null;
  },
  "FETCH_CREDIT_SALES_FAILURE": (state, action) => {
    state.loading = false;
    state.creditSales = [];
    state.error = action.payload;
  },

  "PAY_REMAINING_REQUEST": (state) => {
    state.loading = true;
    state.error = null;
  },
  "PAY_REMAINING_SUCCESS": (state) => {
    state.loading = false;
    state.error = null;
  },
  "PAY_REMAINING_FAILURE": (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },

  "FETCH_DEBT_CLIENTS_REQUEST": (state) => {
    state.loading = true;
    state.error = null;
  },
  "FETCH_DEBT_CLIENTS_SUCCESS": (state, action) => {
    state.loading = false;
    state.debtClients = action.payload;
    state.error = null;
  },
  "FETCH_DEBT_CLIENTS_FAILURE": (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },

  "DELETE_SALE_REQUEST": (state) => {
    state.loading = true;
    state.error = null;
  },
  "DELETE_SALE_SUCCESS": (state, action) => {
    state.loading = false;
    state.cashSales = state.cashSales.filter(sale => sale._id !== action.payload);
    state.bankSales = state.bankSales.filter(sale => sale._id !== action.payload);
    state.creditSales = state.creditSales.filter(sale => sale._id !== action.payload);
    state.error = null;
  },
  "DELETE_SALE_FAILURE": (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },
  "FETCH_DAILY_PRICE_ACHAT_REQUEST": (state) => {
    state.loading = true;
    state.error = null;
  },
  "FETCH_DAILY_PRICE_ACHAT_SUCCESS": (state, action) => {
    state.loading = false;
    state.dailyPriceAchat = action.payload;
    state.error = null;
  },
  "FETCH_DAILY_PRICE_ACHAT_FAILURE": (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },
    //for daily sales
    "FETCH_DAILY_SALES_REQUEST": (state) => {
    state.loading = true;
    state.error = null;
  },
  "FETCH_DAILY_SALES_SUCCESS": (state, action) => {
    state.loading = false;
    state.dailySales = action.payload;
    state.error = null;
  },
  "FETCH_DAILY_SALES_FAILURE": (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },

  "FETCH_MONTHLY_PRICE_ACHAT_REQUEST": (state) => {
    state.loading = true;
    state.error = null;
  },
  "FETCH_MONTHLY_PRICE_ACHAT_SUCCESS": (state, action) => {
    state.loading = false;
    state.monthlyPriceAchat = action.payload;
    state.error = null;
  },
  "FETCH_MONTHLY_PRICE_ACHAT_FAILURE": (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },

  //monthly sales 
  "FETCH_MONTHLY_SALES_REQUEST": (state) => {
    state.loading = true;
    state.error = null;
  },
  "FETCH_MONTHLY_SALES_SUCCESS": (state, action) => {
    state.loading = false;
    state.monthlySales = action.payload;
    state.error = null;
  },
  "FETCH_MONTHLY_SALES_FAILURE": (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },
});

