import React from 'react';
import { Box, Typography, Button, Paper } from '@mui/material';
import { Lock as LockIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const AccessDenied = () => {
  const navigate = useNavigate();

  const handleReturn = () => {
    navigate('/'); // Redirect to home or other appropriate page
  };

  return (
    <Paper elevation={3} sx={{ p: 4, m: 'auto', maxWidth: 600, textAlign: 'center' }}>
      <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
        <LockIcon sx={{ fontSize: 80, color: 'error.main' }} />
        
        <Typography variant="h4" color="text.primary" fontWeight="bold">
          Accès Refusé
        </Typography>
        
        <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
          Désolé, vous n'avez pas l'autorisation de consulter cette page. 
          Veuillez vérifier vos droits d'accès ou contactez l'administrateur si vous pensez que c'est une erreur.
        </Typography>
        
      </Box>
    </Paper>
  );
};

export default AccessDenied;
