import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";


const getConfig = () => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  };
};

// Fetch all shops (for superadmin)
export const fetchAllShops = () => async (dispatch) => {
  try {
    dispatch({ type: "FetchShopsRequest" });

    const { data } = await axios.get(`${server}/shops/shops`, getConfig(), {
      withCredentials: true,
    });

    dispatch({
      type: "FetchShopsSuccess",
      payload: data.shops,
    });
  } catch (error) {
    dispatch({
      type: "FetchShopsFail",
      payload: error.response?.data?.message || "Failed to fetch shops",
    });
  }
};

export const selectShop = (shop) => async (dispatch) => {
  try {
    dispatch({ type: "SelectShopRequest" });

    const { data } = await axios.get(`${server}/shops/shops/${shop._id}`, getConfig(), {
      withCredentials: true,
    });

    dispatch({
      type: "SelectShopSuccess",
      payload: data.shop,
    });

    // After selecting shop, fetch related data
    dispatch(fetchShopProducts(shop._id));
    dispatch(fetchShopSales(shop._id));

    localStorage.setItem("selectedShop", JSON.stringify(data.shop));
  } catch (error) {
    dispatch({
      type: "SelectShopFail",
      payload: error.response?.data?.message || "Failed to select shop",
    });
    toast.error(error.response?.data?.message || "Failed to select shop");
  }
};

export const fetchShopProducts = (shopId) => async (dispatch) => {
  try {
    dispatch({ type: "FetchShopProductsRequest" });

    const { data } = await axios.get(`${server}/products/shop/${shopId}`, getConfig(), {
      withCredentials: true,
    });

    dispatch({
      type: "FetchShopProductsSuccess",
      payload: data.products,
    });
  } catch (error) {
    dispatch({
      type: "FetchShopProductsFail",
      payload: error.response?.data?.message || "Failed to fetch products",
    });
  }
};

export const fetchShopSales = (shopId) => async (dispatch) => {
  try {
    dispatch({ type: "FetchShopSalesRequest" });

    const { data } = await axios.get(`${server}/sales/shop/${shopId}`, getConfig(), {
      withCredentials: true,
    });

    dispatch({
      type: "FetchShopSalesSuccess",
      payload: data.sales,
    });
  } catch (error) {
    dispatch({
      type: "FetchShopSalesFail",
      payload: error.response?.data?.message || "Failed to fetch sales",
    });
  }
};
