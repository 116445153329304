// components/login/Login.jsx

import React, { useState, useEffect } from "react";
import { IconButton, TextField, Button, Grid, Typography, Paper } from "@mui/material";
import { Inventory2Outlined, Visibility, VisibilityOff } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginUser, loadUser } from "../../redux/actions/user";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user, isAuthenticated } = useSelector((state) => state.user);

  const handleSignIn = (e) => {
    e.preventDefault();
    dispatch(loginUser(email, password));
  };

  // Handle redirection based on the user's role and shop
  useEffect(() => {
    if (isAuthenticated && user) {
      if (user.role === "superadmin") {
        navigate("/dashboard");
      } else if (user.shop) {
        switch (user.shop.enum) {
          case "electronics":
            navigate("/produit");
            break;
          case "alimentation":
            navigate("/produit-alimentation");
            break;
          default:
            navigate("/dashboard");
        }
      } else {
        navigate("/dashboard");
      }
    }
  }, [isAuthenticated, user, navigate]);

  // Load user on component mount
  useEffect(() => {
    dispatch(loadUser());
  }, [dispatch]);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ minHeight: "100vh" }}
    >
      <Grid item xs={12} sm={8} md={4}>
        <Paper elevation={3} sx={{ padding: 4, borderRadius: 3 }}>
          <form onSubmit={handleSignIn}>
            <Typography variant="h5" align="center" gutterBottom>
              Bienvenue au Clauvane Shops
            </Typography>

            <TextField
              type="email"
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              sx={{ marginBottom: 2 }}
            />

            <TextField
              type={showPassword ? "text" : "password"}
              label="Mot de passe"
              variant="outlined"
              fullWidth
              margin="normal"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                    aria-label="toggle password visibility"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                ),
              }}
              sx={{ marginBottom: 2 }}
            />

            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <a href="#" style={{ textDecoration: "none", color: "#1976d2" }}>
                  Mot de passe oublié ?
                </a>
              </Grid>
              <Grid item>
                <Button type="submit" variant="contained" color="primary" fullWidth>
                  Se connecter
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default Login;
