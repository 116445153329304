// Depense.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  IconButton,
  CircularProgress,
  InputAdornment,
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  AttachMoney,
  Store as StoreIcon,
  Description as DescriptionIcon,
  TrendingUp,
  TrendingDown,
  CalendarToday,
} from '@mui/icons-material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchDepenses,
  createDepense,
  updateDepense,
  deleteDepense,
  fetchDailyDepenses,
  fetchMonthlyDepenses,
  fetchDailyTotalDepenses,
  fetchMonthlyTotalDepenses,
} from '../../redux/actions/depense';
import { fetchAllShops } from '../../redux/actions/shop';
import { toast } from 'react-toastify';
import Header from "../../components/Header";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";
import moment from 'moment';
import 'moment/locale/fr';
moment.locale('fr');

const formatCurrency = (amount) => {
  if (!amount || isNaN(amount)) return '0 BIF';
  return new Intl.NumberFormat('fr-BI', {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(amount) + ' BIF';
};

const Depense = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const {
    depenses,
    dailyDepenses,
    monthlyDepenses,
    dailyTotalDepenses,
    monthlyTotalDepenses,
    loading,
    error,
  } = useSelector((state) => state.depense);
  const { shops } = useSelector((state) => state.shop);

  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedDepense, setSelectedDepense] = useState(null);
  const [depenseData, setDepenseData] = useState({
    description: '',
    amount: '',
  });
  const [filterShopId, setFilterShopId] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (user) {
      if (user.role === 'superadmin' || user.role === 'admin') {
        dispatch(fetchDepenses(filterShopId));
        if (filterShopId) {
          dispatch(fetchDailyDepenses(filterShopId));
          dispatch(fetchMonthlyDepenses(filterShopId));
        } else {
          dispatch(fetchDailyTotalDepenses());
          dispatch(fetchMonthlyTotalDepenses());
        }
        if (!shops || shops.length === 0) {
          dispatch(fetchAllShops());
        }
      } else {
        dispatch(fetchDepenses(user.shop._id));
        dispatch(fetchDailyDepenses(user.shop._id));
        dispatch(fetchMonthlyDepenses(user.shop._id));
      }
    }
  }, [dispatch, user, filterShopId]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  const handleAddOpen = () => {
    setDepenseData({
      description: '',
      amount: '',
    });
    setOpenAdd(true);
  };

  const handleAddClose = () => {
    setOpenAdd(false);
  };

  const handleEditClose = () => {
    setOpenEdit(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDepenseData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAddSubmit = () => {
    if (!depenseData.description || !depenseData.amount) {
      toast.error('Description and montant are required');
      return;
    }

    const submitData = {
      ...depenseData,
      shopId: user.role === 'superadmin' ? filterShopId : undefined,
    };

    setIsSubmitting(true);
    dispatch(createDepense(submitData))
      .then(() => {
        toast.success('Dépense created successfully');
        setOpenAdd(false);
        setIsSubmitting(false);
        refreshData();
      })
      .catch((error) => {
        toast.error(error);
        setIsSubmitting(false);
      });
  };

  const refreshData = () => {
    if (user.role === 'superadmin' || user.role === 'admin') {
      dispatch(fetchDepenses(filterShopId));
      if (filterShopId) {
        dispatch(fetchDailyDepenses(filterShopId));
        dispatch(fetchMonthlyDepenses(filterShopId));
      } else {
        dispatch(fetchDailyTotalDepenses());
        dispatch(fetchMonthlyTotalDepenses());
      }
    } else {
      dispatch(fetchDepenses(user.shop._id));
      dispatch(fetchDailyDepenses(user.shop._id));
      dispatch(fetchMonthlyDepenses(user.shop._id));
    }
  };

  const handleEditClick = (depense) => {
    setSelectedDepense(depense);
    setDepenseData({
      description: depense.description,
      amount: depense.amount,
    });
    setOpenEdit(true);
  };

  const handleEditSubmit = () => {
    if (!depenseData.description || !depenseData.amount) {
      toast.error('Description and montant are required');
      return;
    }
    setIsSubmitting(true);
    dispatch(updateDepense(selectedDepense._id, depenseData))
      .then(() => {
        toast.success('Depense updated successfully');
        setOpenEdit(false);
        setIsSubmitting(false);
        refreshData();
      })
      .catch((error) => {
        toast.error(error);
        setIsSubmitting(false);
      });
  };

  const handleDelete = (id) => {
    if (window.confirm('Are you sure you want to delete this depense?')) {
      dispatch(deleteDepense(id))
        .then(() => {
          toast.success('Depense deleted successfully');
          refreshData();
        })
        .catch((error) => {
          toast.error(error);
        });
    }
  };

  const columns = [
    {
      field: "createdAt",
      headerName: "Date",
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <CalendarToday sx={{ fontSize: 20, color: colors.greenAccent[500] }} />
          {moment(params.row.createdAt).format('DD/MM/YYYY HH:mm')}
        </Box>
      ),
    },
    {
      field: "description",
      headerName: "Description",
      flex: 2,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <DescriptionIcon sx={{ fontSize: 20, color: colors.greenAccent[500] }} />
          {params.row.description}
        </Box>
      ),
    },
    {
      field: "amount",
      headerName: "Montant",
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <AttachMoney sx={{ fontSize: 20, color: colors.greenAccent[500] }} />
          {formatCurrency(params.row.amount)}
        </Box>
      ),
    },
    {
      field: "shop",
      headerName: "Boutique",
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <StoreIcon sx={{ fontSize: 20, color: colors.greenAccent[500] }} />
          {params.row.shop?.name || 'N/A'}
        </Box>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', gap: 1 }}>
          <IconButton
            onClick={() => handleEditClick(params.row)}
            sx={{
              color: colors.greenAccent[500],
              '&:hover': { color: colors.greenAccent[400] },
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => handleDelete(params.row._id)}
            sx={{
              color: colors.redAccent[500],
              '&:hover': { color: colors.redAccent[400] },
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  // Calculate total amounts safely
  const totalDailyAmount =
    user.role === 'superadmin' || user.role === 'admin'
      ? filterShopId
        ? dailyDepenses?.totalAmount || 0
        : Object.values(dailyTotalDepenses || {}).reduce((a, b) => a + b, 0)
      : dailyDepenses?.totalAmount || 0;

  const totalMonthlyAmount =
    user.role === 'superadmin' || user.role === 'admin'
      ? filterShopId
        ? monthlyDepenses?.totalAmount || 0
        : Object.values(monthlyTotalDepenses || {}).reduce((a, b) => a + b, 0)
      : monthlyDepenses?.totalAmount || 0;

  return (
    <Box m="20px">
      <Header title="DÉPENSES" subtitle="Gestion des dépenses" />

      {/* Summary Cards */}
      <Grid container spacing={3} mb={3}>
        <Grid item xs={12} md={6}>
          <Card
            elevation={3}
            sx={{
              bgcolor: colors.primary[400],
              height: '100%',
              transition: 'transform 0.3s',
              '&:hover': { transform: 'translateY(-5px)' },
            }}
          >
            <CardContent>
              <Box display="flex" justifyContent="space-between">
                <Box>
                  <Typography variant="h6" color={colors.grey[100]}>
                    Dépenses Journalières
                  </Typography>
                  <Typography
                    variant="h3"
                    color={colors.greenAccent[500]}
                    sx={{ mt: 2 }}
                  >
                    {formatCurrency(totalDailyAmount)}
                  </Typography>
                </Box>
                <TrendingUp
                  sx={{
                    fontSize: 60,
                    color: colors.greenAccent[500],
                    opacity: 0.3,
                  }}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card
            elevation={3}
            sx={{
              bgcolor: colors.primary[400],
              height: '100%',
              transition: 'transform 0.3s',
              '&:hover': { transform: 'translateY(-5px)' },
            }}
          >
            <CardContent>
              <Box display="flex" justifyContent="space-between">
                <Box>
                  <Typography variant="h6" color={colors.grey[100]}>
                    Dépenses Mensuelles
                  </Typography>
                  <Typography
                    variant="h3"
                    color={colors.greenAccent[500]}
                    sx={{ mt: 2 }}
                  >
                    {formatCurrency(totalMonthlyAmount)}
                  </Typography>
                </Box>
                <TrendingDown
                  sx={{
                    fontSize: 60,
                    color: colors.greenAccent[500],
                    opacity: 0.3,
                  }}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {/* Controls */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
        gap={2}
        sx={{
          flexDirection: { xs: 'column', sm: 'row' },
          '& > *': { width: { xs: '100%', sm: 'auto' } },
        }}
      >
        {(user.role === 'superadmin' || user.role === 'admin') && (
          <FormControl
            variant="outlined"
            sx={{
              minWidth: { xs: '100%', sm: 250 },
              bgcolor: colors.primary[400],
              borderRadius: 1,
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: colors.greenAccent[500],
                },
                '&:hover fieldset': {
                  borderColor: colors.greenAccent[400],
                },
                '&.Mui-focused fieldset': {
                  borderColor: colors.greenAccent[300],
                },
              },
            }}
          >
            <InputLabel id="shop-filter-label" sx={{ color: colors.grey[100] }}>
              Filtrer par boutique
            </InputLabel>
            <Select
              labelId="shop-filter-label"
              value={filterShopId}
              onChange={(e) => setFilterShopId(e.target.value)}
              label="Filtrer par boutique"
              sx={{ color: colors.grey[100] }}
              startAdornment={
                <InputAdornment position="start">
                  <StoreIcon sx={{ color: colors.greenAccent[500] }} />
                </InputAdornment>
              }
            >
              <MenuItem value="">
                <em>Toutes les boutiques</em>
              </MenuItem>
              {shops.map((shop) => (
                <MenuItem key={shop._id} value={shop._id}>
                  {shop.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <Button
          variant="contained"
          onClick={handleAddOpen}
          sx={{
            bgcolor: colors.greenAccent[500],
            color: colors.grey[900],
            fontSize: '14px',
            fontWeight: 'bold',
            padding: '10px 20px',
            '&:hover': {
              bgcolor: colors.greenAccent[400],
            },
          }}
          startIcon={<AddIcon />}
        >
          Nouvelle Dépense
        </Button>
      </Box>

      {/* DataGrid */}
      <Box
        height="60vh"
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
            bgcolor: colors.primary[400],
            borderRadius: 2,
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none',
            color: colors.grey[100],
          },
          '& .name-column--cell': {
            color: colors.greenAccent[300],
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: colors.greenAccent[600],
            borderBottom: 'none',
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: colors.primary[400],
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
            backgroundColor: colors.greenAccent[600],
          },
          '& .MuiCheckbox-root': {
            color: `${colors.greenAccent[200]} !important`,
          },
          '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={depenses || []}
          columns={columns}
          components={{
            Toolbar: GridToolbar,
            LoadingOverlay: () => (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%"
              >
                <CircularProgress sx={{ color: colors.greenAccent[500] }} />
              </Box>
            ),
          }}
          loading={loading}
          getRowId={(row) => row._id}
        />
      </Box>

      {/* Add/Edit Dialog */}
      <Dialog
        open={openAdd || openEdit}
        onClose={() => (openAdd ? handleAddClose() : handleEditClose())}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            bgcolor: colors.primary[400],
            backgroundImage: 'none',
          },
        }}
      >
        <DialogTitle
          sx={{
            color: colors.grey[100],
            borderBottom: `1px solid ${colors.primary[300]}`,
            mb: 2,
          }}
        >
          {openAdd ? 'Nouvelle Dépense' : 'Modifier la Dépense'}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Description"
                name="description"
                value={depenseData.description}
                onChange={handleChange}
                required
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': { borderColor: colors.grey[400] },
                    '&:hover fieldset': { borderColor: colors.grey[300] },
                    '&.Mui-focused fieldset': {
                      borderColor: colors.greenAccent[500],
                    },
                  },
                  '& .MuiInputLabel-root': { color: colors.grey[100] },
                  '& .MuiInputBase-input': { color: colors.grey[100] },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <DescriptionIcon
                        sx={{ color: colors.greenAccent[500] }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Montant"
                name="amount"
                type="number"
                value={depenseData.amount}
                onChange={handleChange}
                required
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': { borderColor: colors.grey[400] },
                    '&:hover fieldset': { borderColor: colors.grey[300] },
                    '&.Mui-focused fieldset': {
                      borderColor: colors.greenAccent[500],
                    },
                  },
                  '& .MuiInputLabel-root': { color: colors.grey[100] },
                  '& .MuiInputBase-input': { color: colors.grey[100] },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AttachMoney sx={{ color: colors.greenAccent[500] }} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{ color: colors.grey[100] }}
                    >
                      BIF
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{ borderTop: `1px solid ${colors.primary[300]}`, p: 2 }}
        >
          <Button
            onClick={() => (openAdd ? handleAddClose() : handleEditClose())}
            sx={{
              color: colors.grey[300],
              '&:hover': { color: colors.grey[100] },
            }}
          >
            Annuler
          </Button>
          <Button
            onClick={() => (openAdd ? handleAddSubmit() : handleEditSubmit())}
            variant="contained"
            disabled={isSubmitting}
            sx={{
              bgcolor: colors.greenAccent[500],
              color: colors.grey[900],
              '&:hover': {
                bgcolor: colors.greenAccent[400],
              },
              '&.Mui-disabled': {
                bgcolor: colors.grey[500],
              },
            }}
          >
            {isSubmitting ? (
              <CircularProgress size={24} sx={{ color: colors.grey[100] }} />
            ) : openAdd ? (
              'Ajouter'
            ) : (
              'Modifier'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Depense;
