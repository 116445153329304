import axios from 'axios';
import { server } from "../../server";

const getConfig = () => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  };
};

export const fetchProductsAlimentation = (shopId) => async (dispatch) => {
  dispatch({ type: "FetchProductsAlimentationRequest" });
  try {
    const { data } = await axios.get(`${server}/products-alimentation/shop/${shopId}`, getConfig());
    dispatch({ type: "FetchProductsAlimentationSuccess", payload: data.productsAlimentation });
  } catch (error) {
    dispatch({ type: "FetchProductsAlimentationFailure", payload: error.response?.data?.error || 'An error occurred' });
  }
};

export const fetchAllProductsAlimentation = () => async (dispatch) => {
  dispatch({ type: "FetchAllProductsAlimentationRequest" });
  try {
    const { data } = await axios.get(`${server}/products-alimentation/products-alimentation`, getConfig());
    dispatch({ type: "FetchAllProductsAlimentationSuccess", payload: data.productsAlimentation });
  } catch (error) {
    dispatch({ type: "FetchAllProductsAlimentationFailure", payload: error.response?.data?.error || 'An error occurred' });
  }
};

export const createProductAlimentation = (productData) => async (dispatch) => {
  dispatch({ type: "CreateProductAlimentationRequest" });
  try {
    const { data } = await axios.post(`${server}/products-alimentation/create-product-alimentation`, productData, getConfig());
    dispatch({ type: "CreateProductAlimentationSuccess", payload: data.productAlimentation });
    return Promise.resolve(data.productAlimentation);
  } catch (error) {
    dispatch({ type: "CreateProductAlimentationFailure", payload: error.response?.data?.error || 'An error occurred' });
    return Promise.reject(error.response?.data?.error || 'An error occurred');
  }
};

export const updateProductAlimentation = (productId, productData) => async (dispatch) => {
  dispatch({ type: "UpdateProductAlimentationRequest" });
  try {
    const { data } = await axios.put(`${server}/products-alimentation/update-product-alimentation/${productId}`, productData, getConfig());
    dispatch({ type: "UpdateProductAlimentationSuccess", payload: data.productAlimentation });
    return Promise.resolve(data.productAlimentation);
  } catch (error) {
    dispatch({ type: "UpdateProductAlimentationFailure", payload: error.response?.data?.error || 'An error occurred' });
    return Promise.reject(error.response?.data?.error || 'An error occurred');
  }
};

export const deleteProductAlimentation = (productId) => async (dispatch) => {
  dispatch({ type: "DeleteProductAlimentationRequest" });
  try {
    await axios.delete(`${server}/products-alimentation/delete-product-alimentation/${productId}`, getConfig());
    dispatch({ type: "DeleteProductAlimentationSuccess", payload: productId });
    return Promise.resolve();
  } catch (error) {
    dispatch({ type: "DeleteProductAlimentationFailure", payload: error.response?.data?.error || 'An error occurred' });
    return Promise.reject(error.response?.data?.error || 'An error occurred');
  }
};

// Actions for achatProductAlimentation

export const createAchatProductAlimentation = (productId, quantity, description) => async (dispatch) => {
  dispatch({ type: "CreateAchatProductAlimentationRequest" });
  try {
    const { data } = await axios.post(`${server}/achat-product-alimentation/create`, { productId, quantity, description }, getConfig());
    dispatch({ type: "CreateAchatProductAlimentationSuccess", payload: data.data });
    return Promise.resolve(data.data);
  } catch (error) {
    dispatch({ type: "CreateAchatProductAlimentationFailure", payload: error.response?.data?.error || 'An error occurred' });
    return Promise.reject(error.response?.data?.error || 'An error occurred');
  }
};

export const fetchAchatProductsAlimentation = (productId) => async (dispatch) => {
  dispatch({ type: "FetchAchatProductsAlimentationRequest" });
  try {
    const { data } = await axios.get(`${server}/achat-product-alimentation/product/${productId}`, getConfig());
    dispatch({ type: "FetchAchatProductsAlimentationSuccess", payload: data.data });
    return Promise.resolve(data.data);
  } catch (error) {
    dispatch({ type: "FetchAchatProductsAlimentationFailure", payload: error.response?.data?.error || 'An error occurred' });
    return Promise.reject(error.response?.data?.error || 'An error occurred');
  }
};

export const updateAchatProductAlimentation = (achatId, quantity, description) => async (dispatch) => {
  dispatch({ type: "UpdateAchatProductAlimentationRequest" });
  try {
    const { data } = await axios.put(`${server}/achat-product-alimentation/update/${achatId}`, { quantity, description }, getConfig());
    dispatch({ type: "UpdateAchatProductAlimentationSuccess", payload: data.data });
    return Promise.resolve(data.data);
  } catch (error) {
    dispatch({ type: "UpdateAchatProductAlimentationFailure", payload: error.response?.data?.error || 'An error occurred' });
    return Promise.reject(error.response?.data?.error || 'An error occurred');
  }
};

export const deleteAchatProductAlimentation = (achatId) => async (dispatch) => {
  dispatch({ type: "DeleteAchatProductAlimentationRequest" });
  try {
    await axios.delete(`${server}/achat-product-alimentation/delete/${achatId}`, getConfig());
    dispatch({ type: "DeleteAchatProductAlimentationSuccess", payload: achatId });
    return Promise.resolve();
  } catch (error) {
    dispatch({ type: "DeleteAchatProductAlimentationFailure", payload: error.response?.data?.error || 'An error occurred' });
    return Promise.reject(error.response?.data?.error || 'An error occurred');
  }
};
