import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: localStorage.getItem("isAuthenticated") === "true",
  user: JSON.parse(localStorage.getItem("user")) || null,
  users: [],
  loading: false,
  error: null,
};

export const userReducer = createReducer(initialState, (builder) => {
  builder
    // Load User
    .addCase("LoadUserRequest", (state) => {
      state.loading = true;
    })
    .addCase("LoadUserSuccess", (state, action) => {
      state.isAuthenticated = true;
      state.loading = false;
      state.user = action.payload;
      localStorage.setItem("isAuthenticated", "true");
      localStorage.setItem("user", JSON.stringify(action.payload));
    })
    .addCase("LoadUserFail", (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.isAuthenticated = false;
      localStorage.removeItem("isAuthenticated");
      localStorage.removeItem("user");
    })
    // Login User
    .addCase("LoginRequest", (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase("LoginSuccess", (state, action) => {
      state.isAuthenticated = true;
      state.loading = false;
      state.user = action.payload;
      localStorage.setItem("isAuthenticated", "true");
      localStorage.setItem("user", JSON.stringify(action.payload));
    })
    .addCase("LoginFail", (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.isAuthenticated = false;
      localStorage.removeItem("isAuthenticated");
      localStorage.removeItem("user");
    })
    // Logout User
    .addCase("LogoutSuccess", (state) => {
      state.isAuthenticated = false;
      state.user = null;
      localStorage.removeItem("isAuthenticated");
      localStorage.removeItem("user");
    })
    // Fetch Users
    .addCase("FetchUsersRequest", (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase("FetchUsersSuccess", (state, action) => {
      state.loading = false;
      state.users = action.payload;
    })
    .addCase("FetchUsersFail", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    // Create User
    .addCase("CreateUserRequest", (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase("CreateUserSuccess", (state, action) => {
      state.loading = false;
      state.users.unshift(action.payload);
    })
    .addCase("CreateUserFail", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    // Update User
    .addCase("UpdateUserRequest", (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase("UpdateUserSuccess", (state, action) => {
      state.loading = false;
      const index = state.users.findIndex(user => user._id === action.payload._id);
      if (index !== -1) {
        state.users[index] = action.payload;
      }
    })
    .addCase("UpdateUserFail", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    // Delete User
    .addCase("DeleteUserRequest", (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase("DeleteUserSuccess", (state, action) => {
      state.loading = false;
      state.users = state.users.filter(user => user._id !== action.payload);
    })
    .addCase("DeleteUserFail", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
});

export default userReducer;
