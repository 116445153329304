import jsPDF from 'jspdf';
import { nif, RC, phoneNumber } from '../server';
import logo from '../assets/company.png';

const formatNumber = (num) => num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") || "0";

export const generateReceipt = (sale, fullInvoiceNumber) => {
  const doc = new jsPDF({
    unit: 'mm',
    format: [80, 212],
  });

  const pageWidth = 80;
  const margin = 8;
  let yPos = margin;
  const lineHeight = 6;

  const drawDivider = () => {
    doc.line(margin, yPos, pageWidth - margin, yPos);
    yPos += lineHeight / 2;
  };

  const addText = (text, x, y, options = {}) => {
    doc.text(text, x, y, options);
  };

  // Header with Logo and Shop Name
  doc.setFontSize(12);
  doc.setFont('helvetica', 'bold');
  addText('CLAUVANE SHOP', pageWidth / 2, yPos, { align: 'center' });
  yPos += lineHeight;

  try {
    const logoWidth = 20;
    const logoHeight = 20;
    doc.addImage(logo, 'PNG', pageWidth / 2 - logoWidth / 2, yPos, logoWidth, logoHeight);
    yPos += logoHeight + 2;
  } catch (error) {
    console.error('Error adding logo:', error);
  }

  // Contact Info
  doc.setFontSize(8);
  doc.setFont('helvetica', 'normal');
  addText(`NIF: ${nif} | RC: ${RC}`, pageWidth / 2, yPos, { align: 'center' });
  yPos += lineHeight;
  addText(`Tél: ${phoneNumber}`, pageWidth / 2, yPos, { align: 'center' });
  yPos += lineHeight;

  // Business Details
  doc.setDrawColor(200, 200, 200);
  doc.setLineWidth(0.5);
  doc.rect(margin, yPos, pageWidth - margin * 2, lineHeight * 6, 'S');
  yPos += 3;

  const businessDetails = [
    'Commune: NGOZI',
    'Quartier: SHIKIRO',
    'Avenue: Marché de NGOZI',
    'Centre fiscal: Report Nord',
    "Secteur d'activité: C.General",
    'Forme Juridique: P.Morale',
  ];

  businessDetails.forEach((detail) => {
    addText(detail, pageWidth / 2, yPos, { align: 'center' });
    yPos += lineHeight - 2;
  });

  yPos += lineHeight;
  drawDivider();

  // Invoice Details
  doc.setFont('helvetica', 'bold');
  addText('FACTURE', margin, yPos);
  yPos += lineHeight;

  doc.setFont('helvetica', 'normal');
  addText(`Facture N°: ${fullInvoiceNumber}`, margin, yPos);
  addText(`Date: ${new Date(sale.createdAt).toLocaleDateString('fr-FR')}`, pageWidth - margin, yPos, { align: 'right' });
  yPos += lineHeight;

  // Client Info
  yPos += lineHeight / 2;
  doc.setFillColor(245, 245, 245);
  doc.rect(margin, yPos, pageWidth - margin * 2, lineHeight * 4, 'F');
  yPos += lineHeight;

  doc.setFont('helvetica', 'bold');
  addText('Client:', margin, yPos);
  yPos += lineHeight;
  doc.setFont('helvetica', 'normal');
  addText('Nom: ' + (sale.client_name ?? 'N/A'), margin, yPos);
  yPos += lineHeight - 2;
  addText('Téléphone: ' + (sale.client_phone ?? 'N/A'), margin, yPos);
  yPos += lineHeight - 2;

  if (sale.client_address) {
    addText('Adresse: ' + sale.client_address, margin, yPos);
    yPos += lineHeight;
  }

  // Table Headers
  yPos += lineHeight;
  doc.setFont('helvetica', 'bold');
  doc.setFillColor(220, 220, 220); // Light gray background for header
  doc.rect(margin, yPos, pageWidth - margin * 2, lineHeight, 'F'); // Header background
  doc.setDrawColor(200, 200, 200);

  addText('Qté', margin, yPos + 4);
  addText('Description', margin + 10, yPos + 4);
  addText('Prix', pageWidth - margin - 20, yPos + 4, { align: 'right' });
  addText('Total', pageWidth - margin, yPos + 4, { align: 'right' });

  yPos += lineHeight;

  // Product List
  doc.setFont('helvetica', 'normal');
  sale.products.forEach((product, index) => {
    const name = (product.name?.length > 15 ? product.name.substring(0, 12) + '...' : product.name) || 'N/A';
    const quantity = product.quantity?.toString() || '1';
    const price = formatNumber(product.price);
    const total = formatNumber((product.price ?? 0) * (product.quantity ?? 1));

    // Alternating Row Colors
    if (index % 2 === 0) {
      doc.setFillColor(245, 245, 245); // Light alternating row color
      doc.rect(margin, yPos, pageWidth - margin * 2, lineHeight, 'F');
    }

    // Add Product Details
    addText(quantity, margin, yPos + 4);
    addText(name, margin + 10, yPos + 4);
    addText(price, pageWidth - margin - 20, yPos + 4, { align: 'right' });
    addText(total, pageWidth - margin, yPos + 4, { align: 'right' });

    // Draw Row Border
    doc.setDrawColor(220, 220, 220);
    doc.line(margin, yPos + lineHeight, pageWidth - margin, yPos + lineHeight);

    yPos += lineHeight;
  });

  // Totals
  yPos += lineHeight / 2;
  doc.setFont('helvetica', 'bold');
  doc.setFontSize(8);

  const totals = [
    { label: 'Total', amount: sale.total_amount },
    { label: 'Payé', amount: sale.amount_paid },
    ...(sale.remaining_amount > 0 ? [{ label: 'Reste', amount: sale.remaining_amount }] : []),
  ];

  totals.forEach(({ label, amount }) => {
    addText(label, margin, yPos);
    addText(formatNumber(amount) + ' BIF', pageWidth - margin, yPos, { align: 'right' });
    yPos += lineHeight;
  });

  // Footer
  yPos += lineHeight * 1.5;
  doc.setFont('helvetica', 'italic');
  addText('Merci de votre confiance!', pageWidth / 2, yPos, { align: 'center' });
  yPos += lineHeight;
  doc.setFont('helvetica', 'normal');
  addText(`NIF: ${nif} | RC: ${RC} | Tél: ${phoneNumber}`, pageWidth / 2, yPos, { align: 'center' });
  yPos += lineHeight;
  addText('Système développé par hyphetech.tech', pageWidth / 2, yPos, { align: 'center' });

  // Adjust Page Height
  yPos += margin;
  doc.internal.pageSize.setHeight(yPos);

  return doc;
};
