import axios from 'axios';
import { server } from "../../server";

const getConfig = () => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  };
};

export const fetchProducts = (shopId) => async (dispatch) => {
  dispatch({ type: "FetchProductsRequest" });
  try {
    const { data } = await axios.get(`${server}/products/shop/${shopId}`, getConfig());
    dispatch({ type: "FetchProductsSuccess", payload: data.products });
  } catch (error) {
    dispatch({ type: "FetchProductsFailure", payload: error.response?.data?.error || 'An error occurred' });
  }
};

export const fetchAllProducts = () => async (dispatch) => {
  dispatch({ type: "FetchAllProductsRequest" });
  try {
    const { data } = await axios.get(`${server}/products/all-products`, getConfig());
    dispatch({ type: "FetchAllProductsSuccess", payload: data.products });
  } catch (error) {
    dispatch({ type: "FetchAllProductsFailure", payload: error.response?.data?.error || 'An error occurred' });
  }
};

export const createProduct = (productData) => async (dispatch) => {
  dispatch({ type: "CreateProductRequest" });
  try {
    const { data } = await axios.post(`${server}/products/create-product`, productData, getConfig());
    dispatch({ type: "CreateProductSuccess", payload: data.product });
    return Promise.resolve(data.product);
  } catch (error) {
    dispatch({ type: "CreateProductFailure", payload: error.response?.data?.error || 'An error occurred' });
    return Promise.reject(error.response?.data?.error || 'An error occurred');
  }
};

export const updateProduct = (productId, productData) => async (dispatch) => {
  dispatch({ type: "UpdateProductRequest" });
  try {
    const { data } = await axios.put(`${server}/products/update-product/${productId}`, productData, getConfig());
    dispatch({ type: "UpdateProductSuccess", payload: data.product });
    return Promise.resolve(data.product);
  } catch (error) {
    dispatch({ type: "UpdateProductFailure", payload: error.response?.data?.error || 'An error occurred' });
    return Promise.reject(error.response?.data?.error || 'An error occurred');
  }
};

export const deleteProduct = (productId) => async (dispatch) => {
  dispatch({ type: "DeleteProductRequest" });
  try {
    await axios.delete(`${server}/products/delete-product/${productId}`, getConfig());
    dispatch({ type: "DeleteProductSuccess", payload: productId });
    return Promise.resolve();
  } catch (error) {
    dispatch({ type: "DeleteProductFailure", payload: error.response?.data?.error || 'An error occurred' });
    return Promise.reject(error.response?.data?.error || 'An error occurred');
  }
};

// Actions for achatProduit

export const createAchatProduct = (productId, quantity) => async (dispatch) => {
  dispatch({ type: "CreateAchatProductRequest" });
  try {
    const { data } = await axios.post(`${server}/achat-products/create`, { productId, quantity }, getConfig());
    dispatch({ type: "CreateAchatProductSuccess", payload: data.data });
    return Promise.resolve(data.data);
  } catch (error) {
    dispatch({ type: "CreateAchatProductFailure", payload: error.response?.data?.error || 'An error occurred' });
    return Promise.reject(error.response?.data?.error || 'An error occurred');
  }
};

export const fetchAchatProducts = (productId) => async (dispatch) => {
  dispatch({ type: "FetchAchatProductsRequest" });
  try {
    const { data } = await axios.get(`${server}/achat-products/product/${productId}`, getConfig());
    dispatch({ type: "FetchAchatProductsSuccess", payload: data.data });
    return Promise.resolve(data.data);
  } catch (error) {
    dispatch({ type: "FetchAchatProductsFailure", payload: error.response?.data?.error || 'An error occurred' });
    return Promise.reject(error.response?.data?.error || 'An error occurred');
  }
};

export const updateAchatProduct = (achatId, quantity) => async (dispatch) => {
  dispatch({ type: "UpdateAchatProductRequest" });
  try {
    const { data } = await axios.put(`${server}/achat-products/update/${achatId}`, { quantity }, getConfig());
    dispatch({ type: "UpdateAchatProductSuccess", payload: data.data });
    return Promise.resolve(data.data);
  } catch (error) {
    dispatch({ type: "UpdateAchatProductFailure", payload: error.response?.data?.error || 'An error occurred' });
    return Promise.reject(error.response?.data?.error || 'An error occurred');
  }
};

export const deleteAchatProduct = (achatId) => async (dispatch) => {
  dispatch({ type: "DeleteAchatProductRequest" });
  try {
    await axios.delete(`${server}/achat-products/delete/${achatId}`, getConfig());
    dispatch({ type: "DeleteAchatProductSuccess", payload: achatId });
    return Promise.resolve();
  } catch (error) {
    dispatch({ type: "DeleteAchatProductFailure", payload: error.response?.data?.error || 'An error occurred' });
    return Promise.reject(error.response?.data?.error || 'An error occurred');
  }
};
