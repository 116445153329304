import axios from "axios";
import { server } from "../server";

const getConfig = () => {
  const token = localStorage.getItem("token");
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
};

// Fetch total price_achat for alimentation shop
export const fetchTotalAlimentationPriceAchat = async () => {
  try {
    const response = await axios.get(`${server}/sales/total-alimentation-price-achat`, getConfig());
    return response.data.totalPriceAchat;
  } catch (error) {
    console.error("Error fetching total alimentation price achat:", error);
    throw error;
  }
};

// Fetch total price_achat for the first electronics shop
export const fetchTotalElectronicsPriceAchatFirstShop = async () => {
  try {
    const response = await axios.get(`${server}/sales/total-electronics-price-achat-first`, getConfig());
    return response.data.totalPriceAchat;
  } catch (error) {
    console.error("Error fetching total electronics price achat (first shop):", error);
    throw error;
  }
};

// Fetch total price_achat for the second electronics shop
export const fetchTotalElectronicsPriceAchatSecondShop = async () => {
  try {
    const response = await axios.get(`${server}/sales/total-electronics-price-achat-second`, getConfig());
    return response.data.totalPriceAchat;
  } catch (error) {
    console.error("Error fetching total electronics price achat (second shop):", error);
    throw error;
  }
};

// Direct exports to use them as variables
export const getTotals = async () => {
  try {
    const totalAlimentation = await fetchTotalAlimentationPriceAchat();
    const totalElectronicsFirstShop = await fetchTotalElectronicsPriceAchatFirstShop();
    const totalElectronicsSecondShop = await fetchTotalElectronicsPriceAchatSecondShop();

    return {
      totalAlimentation,
      totalElectronicsFirstShop,
      totalElectronicsSecondShop,
    };
  } catch (error) {
    console.error("Error fetching totals:", error);
    throw error;
  }
};

export default {
  fetchTotalAlimentationPriceAchat,
  fetchTotalElectronicsPriceAchatFirstShop,
  fetchTotalElectronicsPriceAchatSecondShop,
  getTotals,
};


// import { getTotals } from './api';

// const fetchAndUseTotals = async () => {
//   try {
//     const { totalAlimentation, totalElectronicsFirstShop, totalElectronicsSecondShop } = await getTotals();

//     console.log("Total Alimentation:", totalAlimentation);
//     console.log("Total Electronics (First Shop):", totalElectronicsFirstShop);
//     console.log("Total Electronics (Second Shop):", totalElectronicsSecondShop);

//     // Use the totals directly in your logic
//   } catch (error) {
//     console.error("Error fetching totals:", error);
//   }
// };

// fetchAndUseTotals();