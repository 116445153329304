import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';

const PaymentHistoryDialog = ({ open, onClose, paymentHistory }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Historique des paiements</DialogTitle>
      <DialogContent>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Montant</TableCell>
                <TableCell>Mode de paiement</TableCell>
                <TableCell>Effectué par</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentHistory?.map((payment, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {new Date(payment.payment_date).toLocaleString('fr-FR', {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit'
                    })}
                  </TableCell>
                  <TableCell>{payment.amount?.toLocaleString()} Fbu</TableCell>
                  <TableCell>
                    {payment.payment_method.toLowerCase() === 'debt'
                      ? 'Avance avec dette'
                      : payment.payment_method.toUpperCase()}
                  </TableCell>
                  <TableCell>{payment.user?.name || 'N/A'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
};

export default PaymentHistoryDialog; 