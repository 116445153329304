import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  useTheme,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import {
  Visibility,
  VisibilityOff,
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchUsers,
  createUser,
  updateUser,
  deleteUser,
} from '../../redux/actions/user';
import { fetchAllShops } from '../../redux/actions/shop';
import Header from "../../components/Header";

const Team = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();

  // Access Redux state
  const { user, users, loading, error } = useSelector(state => state.user);
  const { shops } = useSelector(state => state.shop);

  // Local state for dialog visibility
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  // Local state for form data
  const [userData, setUserData] = useState({
    name: '',
    email: '',
    role: 'employee',
    shop: '',
    password: '',
    status: 'active',
  });

  // State for password visibility
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (user && user.role === 'superadmin') {
      dispatch(fetchUsers());
      dispatch(fetchAllShops());
    }
  }, [dispatch, user]);

  // Handle form changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle password visibility toggle
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // Handle mouse down on password icon
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // Handle opening the add user dialog
  const handleAddOpen = () => {
    setUserData({
      name: '',
      email: '',
      role: 'employee',
      shop: '',
      password: '',
      status: 'active',
    });
    setOpenAdd(true);
  };

  // Handle closing the add user dialog
  const handleAddClose = () => {
    setOpenAdd(false);
  };

  // Handle adding a user
  const handleAddSubmit = () => {
    // Validate the form data
    if (!userData.name || !userData.email || !userData.password) {
      // Show error message
      alert('Please fill in all required fields');
      return;
    }

    // Dispatch createUser action
    dispatch(createUser(userData)).then(() => {
      setOpenAdd(false);
    });
  };

  // Handle opening the edit user dialog
  const handleEditOpen = (user) => {
    setSelectedUser(user);
    setUserData({
      name: user.name,
      email: user.email,
      role: user.role,
      shop: user.shop ? user.shop._id : '',
      password: '',
      status: user.status,
    });
    setOpenEdit(true);
  };

  // Handle closing the edit user dialog
  const handleEditClose = () => {
    setOpenEdit(false);
  };

  // Handle updating a user
  const handleEditSubmit = () => {
    if (!userData.name || !userData.email) {
      alert('Please fill in all required fields');
      return;
    }

    // Prepare the data to send (exclude password if empty)
    const updateData = { ...userData };
    if (!updateData.password) {
      delete updateData.password;
    }

    // Dispatch updateUser action
    dispatch(updateUser(selectedUser._id, updateData)).then(() => {
      setOpenEdit(false);
    });
  };

  // Handle deleting a user
  const handleDelete = (id) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      dispatch(deleteUser(id));
    }
  };

  // Define columns
  const columns = [

    {
      field: "name",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      width: 200,
    },
    {
      field: "role",
      headerName: "Role",
      flex: 1,
    },
    {
      field: "shop",
      headerName: "Shop",
      flex: 1,
      valueGetter: (params) => params.row.shop ? params.row.shop.name : 'N/A',
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Box>
          <IconButton onClick={() => handleEditOpen(params.row)} color="primary">
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDelete(params.row._id)} color="error">
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Header title="TEAM" subtitle="Gérer les membres du personnel" />
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<AddIcon />}
          onClick={handleAddOpen}
        >
          Ajouter un membre
        </Button>
      </Box>
      <Box
        m="0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
            color: colors.grey[100], // Set the text color to white
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
            fontWeight: "bold",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            color: colors.grey[100],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-row": {
            fontSize: "14px",
          },
        }}
      >
        <DataGrid
          rows={users}
          columns={columns}
          loading={loading}
          getRowId={(row) => row._id}
          autoHeight
          pageSize={5}
          rowsPerPageOptions={[5, 10, 20]}
          disableSelectionOnClick
        />
      </Box>

      {/* Add User Dialog */}
      <Dialog open={openAdd} onClose={handleAddClose}>
        <DialogTitle>Ajouter un membre</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Nom"
            name="name"
            value={userData.name}
            onChange={handleChange}
            fullWidth
            required
          />
          <TextField
            margin="dense"
            label="Email"
            name="email"
            value={userData.email}
            onChange={handleChange}
            fullWidth
            required
          />
          <FormControl fullWidth margin="dense">
            <InputLabel id="role-label">Role</InputLabel>
            <Select
              labelId="role-label"
              name="role"
              value={userData.role}
              onChange={handleChange}
              label="Role"
            >
              <MenuItem value="employee">Employé</MenuItem>
              <MenuItem value="manager">Manager</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth margin="dense">
            <InputLabel id="shop-label">Shop</InputLabel>
            <Select
              labelId="shop-label"
              name="shop"
              value={userData.shop}
              onChange={handleChange}
              label="Shop"
            >
              {shops && shops.map((shop) => (
                <MenuItem key={shop._id} value={shop._id}>{shop.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="dense" variant="outlined">
            <InputLabel htmlFor="password">Password</InputLabel>
            <OutlinedInput
              id="password"
              name="password"
              type={showPassword ? 'text' : 'password'}
              value={userData.password}
              onChange={handleChange}
              label="Password"
              required
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControl fullWidth margin="dense">
            <InputLabel id="status-label">Status</InputLabel>
            <Select
              labelId="status-label"
              name="status"
              value={userData.status}
              onChange={handleChange}
              label="Status"
            >
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="inactive">Inactive</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddClose}>Annuler</Button>
          <Button onClick={handleAddSubmit} color="secondary">Ajouter</Button>
        </DialogActions>
      </Dialog>

      {/* Edit User Dialog */}
      <Dialog open={openEdit} onClose={handleEditClose}>
        <DialogTitle>Modifier un membre</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Nom"
            name="name"
            value={userData.name}
            onChange={handleChange}
            fullWidth
            required
          />
          <TextField
            margin="dense"
            label="Email"
            name="email"
            value={userData.email}
            onChange={handleChange}
            fullWidth
            required
          />
          <FormControl fullWidth margin="dense">
            <InputLabel id="role-label">Role</InputLabel>
            <Select
              labelId="role-label"
              name="role"
              value={userData.role}
              onChange={handleChange}
              label="Role"
            >
              <MenuItem value="employee">Employé</MenuItem>
              <MenuItem value="manager">Manager</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth margin="dense">
            <InputLabel id="shop-label">Shop</InputLabel>
            <Select
              labelId="shop-label"
              name="shop"
              value={userData.shop}
              onChange={handleChange}
              label="Shop"
            >
              {shops && shops.map((shop) => (
                <MenuItem key={shop._id} value={shop._id}>{shop.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="dense" variant="outlined">
            <InputLabel htmlFor="password">Password</InputLabel>
            <OutlinedInput
              id="password"
              name="password"
              type={showPassword ? 'text' : 'password'}
              value={userData.password}
              onChange={handleChange}
              label="Password"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <Typography variant="caption">
              Laisser vide pour conserver le mot de passe actuel
            </Typography>
          </FormControl>
          <FormControl fullWidth margin="dense">
            <InputLabel id="status-label">Status</InputLabel>
            <Select
              labelId="status-label"
              name="status"
              value={userData.status}
              onChange={handleChange}
              label="Status"
            >
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="inactive">Inactive</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose}>Annuler</Button>
          <Button onClick={handleEditSubmit} color="secondary">Enregistrer</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Team;
