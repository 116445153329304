import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  useTheme,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  CircularProgress,
  Typography,
  List,
  ListItem,
  ListItemText,
  Paper,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme"; // Adjust the import path as necessary
import Header from "../../components/Header"; // Adjust the import path as necessary
import { Delete, Edit, Search, ShoppingCart } from "@mui/icons-material";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchProductsAlimentation,
  fetchAllProductsAlimentation,
  createProductAlimentation,
  updateProductAlimentation,
  deleteProductAlimentation,
  createAchatProductAlimentation,
  fetchAchatProductsAlimentation,
  updateAchatProductAlimentation,
  deleteAchatProductAlimentation,
} from "../../redux/actions/productAlimentation"; // Adjust the import paths as necessary
import { fetchAllShops } from "../../redux/actions/shop"; // Adjust the import paths as necessary
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';

const ProductAlimentation = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { productsAlimentation, achatProductsAlimentation, loading, error } = useSelector((state) => state.productAlimentation);
  const { shops } = useSelector((state) => state.shop);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openAchat, setOpenAchat] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (user?.role === 'superadmin') {
      dispatch(fetchAllShops());
      dispatch(fetchAllProductsAlimentation());
    } else if (user?.shop?._id) {
      dispatch(fetchProductsAlimentation(user.shop._id));
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  useEffect(() => {
    if (selectedProduct) {
      dispatch(fetchAchatProductsAlimentation(selectedProduct._id));
    }
  }, [selectedProduct, dispatch]);

  const filteredProducts = productsAlimentation.filter(product =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    product.category.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const columns = [
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      valueGetter: (params) => moment(params.row.createdAt).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell"
    },
    { field: "category", headerName: "Category", flex: 1 },
    { field: "price_achat", headerName: "Price Achat", type: "number", flex: 1 },
    { field: "stock", headerName: "Stock", type: "number", flex: 0.5 }, {
      field: "expiration_date",
      headerName: " Va expire le:",
      flex: 1,
      valueGetter: (params) => moment(params.row.expiration_date).format('YYYY-MM-DD')
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Box>
          <IconButton onClick={() => handleAchatOpen(params.row)} color="error">
            <ShoppingCart />
          </IconButton>
          <>
            <IconButton onClick={() => handleEditClick(params.row)} color="secondary">
              <Edit />
            </IconButton>
            <IconButton onClick={() => handleDelete(params.row._id)} color="error">
              <Delete />
            </IconButton>
          </>
        </Box>
      ),
    },
  ];

  const handleAddOpen = () => setOpenAdd(true);
  const handleAddClose = () => setOpenAdd(false);

  const handleEditOpen = () => setOpenEdit(true);
  const handleEditClose = () => setOpenEdit(false);

  const handleAchatOpen = (product) => {
    setSelectedProduct(product);
    setOpenAchat(true);
  };

  const handleAchatClose = () => {
    setOpenAchat(false);
    setSelectedProduct(null);
  };

  const handleAddProduct = async (productData) => {
    setIsSubmitting(true);
    const productToSubmit = {
      ...productData,
      price_achat: Number(productData.price_achat),
      stock: Number(productData.stock),
    };
    if (user?.role !== 'superadmin') {
      productToSubmit.shop = user.shop._id;
    }

    try {
      await dispatch(createProductAlimentation(productToSubmit));
      handleAddClose();
      toast.success("Product added successfully!");
      if (user?.role === 'superadmin') {
        dispatch(fetchAllProductsAlimentation());
      } else {
        dispatch(fetchProductsAlimentation(user.shop._id));
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleEditClick = (product) => {
    setSelectedProduct(product);
    handleEditOpen();
  };

  const handleEditProduct = async (productData) => {
    setIsSubmitting(true);
    const productToSubmit = {
      ...productData,
      price_achat: Number(productData.price_achat),
      stock: Number(productData.stock),
    };

    try {
      await dispatch(updateProductAlimentation(selectedProduct._id, productToSubmit));
      handleEditClose();
      toast.success("Product updated successfully!");
      if (user?.role === 'superadmin') {
        dispatch(fetchAllProductsAlimentation());
      } else {
        dispatch(fetchProductsAlimentation(user.shop._id));
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this product?")) {
      try {
        await dispatch(deleteProductAlimentation(id));
        toast.success("Product deleted successfully!");
        if (user?.role === 'superadmin') {
          dispatch(fetchAllProductsAlimentation());
        } else {
          dispatch(fetchProductsAlimentation(user.shop._id));
        }
      } catch (error) {
        toast.error(error);
      }
    }
  };

  const handleAchatSubmit = async (achatData) => {
    if (!achatData.quantity) {
      toast.error("Quantity is required.");
      return;
    }

    setIsSubmitting(true);
    try {
      await dispatch(createAchatProductAlimentation(selectedProduct._id, Number(achatData.quantity), achatData.description));
      toast.success("Achat product added successfully!");
      dispatch(fetchAchatProductsAlimentation(selectedProduct._id));
      if (user?.role === 'superadmin') {
        dispatch(fetchAllProductsAlimentation());
      } else {
        dispatch(fetchProductsAlimentation(user.shop._id));
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleAchatEdit = async (achatId) => {
    const newQuantity = prompt('Enter new quantity:');
    const newDescription = prompt('Enter new description:');
    if (newQuantity !== null && newDescription !== null) {
      try {
        await dispatch(updateAchatProductAlimentation(achatId, Number(newQuantity), newDescription));
        toast.success("Achat product updated successfully!");
        dispatch(fetchAchatProductsAlimentation(selectedProduct._id));
        if (user?.role === 'superadmin') {
          dispatch(fetchAllProductsAlimentation());
        } else {
          dispatch(fetchProductsAlimentation(user.shop._id));
        }
      } catch (error) {
        toast.error(error);
      }
    }
  };

  const handleAchatDelete = async (achatId) => {
    if (window.confirm("Are you sure you want to delete this achat?")) {
      try {
        await dispatch(deleteAchatProductAlimentation(achatId));
        toast.success("Achat product deleted successfully!");
        dispatch(fetchAchatProductsAlimentation(selectedProduct._id));
        if (user?.role === 'superadmin') {
          dispatch(fetchAllProductsAlimentation());
        } else {
          dispatch(fetchProductsAlimentation(user.shop._id));
        }
      } catch (error) {
        toast.error(error);
      }
    }
  };

  const ProductForm = ({ initialProduct, isEdit, onSubmit, onCancel, user, shops }) => {
    const [product, setProduct] = useState(initialProduct);

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setProduct(prev => ({ ...prev, [name]: value }));
    };

    const handleFormSubmit = () => {
      if (!product.name || !product.category) {
        toast.error("Name and Category are required.");
        return;
      }
      if (user?.role === 'superadmin' && !product.shop) {
        toast.error("Shop is required for superadmin.");
        return;
      }
      onSubmit(product);
    };

    return (
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="dense"
              name="name"
              label="Name"
              type="text"
              fullWidth
              variant="outlined"
              value={product.name}
              onChange={handleInputChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="dense" required>
              <InputLabel>Catégorie</InputLabel>
              <Select
                name="category"
                value={product.category}
                onChange={handleInputChange}
                label="Category"
              >
                <MenuItem value="boisson">Boisson</MenuItem>
                <MenuItem value="huiles">Huiles</MenuItem>
                <MenuItem value="savons-liquides">Savons Liquides</MenuItem>
                <MenuItem value="savons-solides">Savons Solides</MenuItem>
                <MenuItem value="lait-liquide">Lait Liquide</MenuItem>
                <MenuItem value="lait-farine">Lait Farine</MenuItem>
                <MenuItem value="Eau">Eau</MenuItem>
                <MenuItem value="Serviette-usage-unique">Serviette Usage Unique</MenuItem>
                <MenuItem value="serviette-tissu">Serviette Tissu</MenuItem>
                <MenuItem value="serviette-en-color">Serviette En Color</MenuItem>
                <MenuItem value="serviette-cotex">Serviette Cotex</MenuItem>
                <MenuItem value="Aliment-nourriture">Aliment Nourriture</MenuItem>
                <MenuItem value="produit-forever">Produit Forever</MenuItem>
                <MenuItem value="produit-sante">Produit Santé</MenuItem>
                <MenuItem value="Autres">Autres</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {user?.role === 'superadmin' && (
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth margin="dense" required>
                <InputLabel>Shop</InputLabel>
                <Select
                  name="shop"
                  value={product.shop || ''}
                  onChange={handleInputChange}
                  label="Shop"
                >
                  {shops.map((shop) => (
                    <MenuItem key={shop._id} value={shop._id}>{shop.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <TextField
              margin="dense"
              name="price_achat"
              label="Price Achat"
              type="number"
              fullWidth
              variant="outlined"
              value={product.price_achat}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="dense"
              name="expiration_date"
              label="Expiration Date"
              type="date"
              fullWidth
              variant="outlined"
              value={product.expiration_date ? moment(product.expiration_date).format('YYYY-MM-DD') : ''}
              onChange={handleInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="dense"
              name="stock"
              label="Stock"
              type="number"
              fullWidth
              variant="outlined"
              value={product.stock}
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>
        <DialogActions>
          <Button onClick={onCancel} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleFormSubmit}
            color="secondary"
            variant="contained"
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={24} /> : isEdit ? "Save" : "Add"}
          </Button>
        </DialogActions>
      </DialogContent>
    );
  };

  const AchatForm = ({ onSubmit }) => {
    const [achatData, setAchatData] = useState({ quantity: '', description: '' });

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setAchatData(prev => ({ ...prev, [name]: value }));
    };

    const handleFormSubmit = () => {
      onSubmit(achatData);
    };

    return (
      <>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="quantity"
                label="Quantity"
                type="number"
                fullWidth
                variant="outlined"
                value={achatData.quantity}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="description"
                label="Description"
                type="text"
                fullWidth
                variant="outlined"
                value={achatData.description}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={handleFormSubmit}
                color="secondary"
                variant="contained"
                disabled={isSubmitting}
                fullWidth
              >
                {isSubmitting ? <CircularProgress size={24} /> : "Add Achat"}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </>
    );
  };

  return (
    <Box m="20px">
      <Header title="PRODUITS ALIMENTATION" subtitle="Gestion des produits alimentaires" />
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Box display="flex" alignItems="center">
          <TextField
            label="Search"
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Button
          onClick={handleAddOpen}
          color="secondary"
          variant="contained"
        >
          Add Product
        </Button>
      </Box>

      {/* Add Product Dialog */}
      <Dialog open={openAdd} onClose={handleAddClose} maxWidth="md" fullWidth>
        <DialogTitle>Add New Product</DialogTitle>
        <ProductForm
          initialProduct={{
            name: "",
            category: "",
            price_achat: "",
            expiration_date: "",
            stock: "",
            shop: user?.role === 'superadmin' ? '' : user.shop._id,
          }}
          isEdit={false}
          onSubmit={handleAddProduct}
          onCancel={handleAddClose}
          user={user}
          shops={shops}
        />
      </Dialog>

      {/* Edit Product Dialog */}
      <Dialog open={openEdit} onClose={handleEditClose} maxWidth="md" fullWidth>
        <DialogTitle>Edit Product</DialogTitle>
        {selectedProduct && (
          <ProductForm
            initialProduct={{ ...selectedProduct, shop: selectedProduct.shop?._id }}
            isEdit={true}
            onSubmit={handleEditProduct}
            onCancel={handleEditClose}
            user={user}
            shops={shops}
          />
        )}
      </Dialog>

      {/* Achat Product Dialog */}
      <Dialog open={openAchat} onClose={handleAchatClose} maxWidth="md" fullWidth>
        <DialogTitle>Achat Product - {selectedProduct?.name}</DialogTitle>
        <AchatForm onSubmit={handleAchatSubmit} />
        <Typography variant="h6" style={{ marginTop: '20px', marginLeft: '20px' }}>Achat History</Typography>
        <DialogContent>
          <Paper style={{ maxHeight: 300, overflow: 'auto', marginTop: '10px' }}>
            <List>
              {achatProductsAlimentation.map((achat) => (
                <ListItem key={achat._id}>
                  <ListItemText
                    primary={`Quantity: ${achat.quantity}`}
                    secondary={
                      <>
                        <Typography component="span" variant="body2" color="textPrimary">
                          Date: {moment(achat.date).format('YYYY-MM-DD HH:mm:ss')}
                        </Typography>
                        <br />
                        <Typography component="span" variant="body2" color="textPrimary">
                          User: {achat.user.name}
                        </Typography>
                        {achat.description && (
                          <>
                            <br />
                            <Typography component="span" variant="body2" color="textPrimary">
                              Description: {achat.description}
                            </Typography>
                          </>
                        )}
                      </>
                    }
                  />
                  <IconButton onClick={() => handleAchatEdit(achat._id)} color="secondary">
                    <Edit />
                  </IconButton>
                  <IconButton onClick={() => handleAchatDelete(achat._id)} color="error">
                    <Delete />
                  </IconButton>
                </ListItem>
              ))}
            </List>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAchatClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Data Grid */}
      <Box
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
            color: colors.grey[100],
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
            color: colors.grey[100],
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            color: colors.grey[100],
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={filteredProducts}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          loading={loading}
          getRowId={(row) => row._id}
        />
      </Box>
    </Box>
  );
};

export default ProductAlimentation;
