import { useEffect, useState } from "react";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import {
  Box,
  IconButton,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";

// Icons import
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import FastfoodOutlinedIcon from "@mui/icons-material/FastfoodOutlined";
import MoneyOffOutlinedIcon from "@mui/icons-material/MoneyOffOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import PointOfSaleOutlinedIcon from "@mui/icons-material/PointOfSaleOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import HistoryOutlined from "@mui/icons-material/HistoryOutlined";

import logo from "../../assets/company.png";
import { useSelector } from "react-redux";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <MenuItem
      active={selected === title}
      style={{
        color: selected === title ? colors.blueAccent[500] : colors.grey[100],
        borderRadius: "8px",
        transition: "all 0.2s ease-in-out",
      }}
      onClick={() => setSelected(title)} // Set selected on click
      icon={icon}
    >
      <Typography variant="body1" fontWeight="500">
        {title}
      </Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = ({ setIsCollapsed }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsedState] = useState(false);
  const [selected, setSelected] = useState(
    localStorage.getItem("selectedMenu") || ""
  );
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { isAuthenticated, user } = useSelector((state) => state.user);

  useEffect(() => {
    localStorage.setItem("selectedMenu", selected); // Store selected item in local storage
  }, [selected]);

  useEffect(() => {
    setIsCollapsed(isCollapsed);
  }, [isCollapsed]);

  const handleCollapse = () => {
    setIsCollapsedState(!isCollapsed);
  };

  return (
    <Box
      sx={{
        position: "fixed",
        height: "100vh",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        zIndex: 1000,
        width: isCollapsed ? "80px" : "270px",
        transition: "width 0.3s ease",
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
          borderRight: `1px solid ${colors.grey[800]}`,
          padding: isCollapsed ? "10px 0" : "20px 0",
          height: "100%",
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "8px 24px !important",
        },
        "& .pro-inner-item:hover": {
          backgroundColor: colors.grey[700],
          color: `${colors.blueAccent[500]} !important`,
          borderRadius: "8px",
        },
        "& .pro-menu-item.active": {
          backgroundColor: `${colors.blueAccent[500]} !important`,
          color: "#fff !important",
          borderRadius: "8px",
        },
        "&::-webkit-scrollbar": {
          width: "6px",
        },
        "&::-webkit-scrollbar-track": {
          background: colors.primary[400],
        },
        "&::-webkit-scrollbar-thumb": {
          background: colors.grey[700],
          borderRadius: "3px",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          background: colors.grey[600],
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="circle">
          <MenuItem
            onClick={handleCollapse}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h6" color={colors.grey[100]}>
                  CLAUVANE SHOP
                </Typography>
                <IconButton onClick={handleCollapse}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {/* User Info Section */}
          {!isCollapsed && (
            <Box mb="20px" textAlign="center">
              <img
                alt="logo"
                width="80px"
                height="80px"
                src={logo} // Replace with your logo path
                style={{
                  cursor: "pointer",
                  borderRadius: "50%",
                  marginBottom: "10px",
                  border: `2px solid ${colors.greenAccent[500]}`,
                }}
              />
              {isAuthenticated ? (
                <>
                  <Typography variant="h6" color={colors.grey[100]}>
                    {user.name}
                  </Typography>
                  <Typography variant="body2" color={colors.greenAccent[500]}>
                    {user.role}
                  </Typography>
                </>
              ) : (
                <>
                  <Typography variant="h6" color={colors.grey[100]}>
                    Ed Roh
                  </Typography>
                  <Typography variant="body2" color={colors.greenAccent[500]}>
                    role: Admin
                  </Typography>
                </>
              )}
            </Box>
          )}

          {/* Menu Items */}
          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <Item
              title="Dashboard"
              to="/Dashboard"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Produits"
              to="/produit"
              icon={<Inventory2OutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Produits alimentation"
              to="/produit-alimentation"
              icon={<FastfoodOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Les depenses"
              to="/depenses"
              icon={<MoneyOffOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Ventes produits"
              to="/vente"
              icon={<ShoppingCartOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <SubMenu
              title="Ventes Alimentation"
              icon={<FastfoodOutlinedIcon />}
              style={{
                color: selected === "TransactionAlimentation" ? colors.blueAccent[500] : colors.grey[100],
              }}
            >
              <Item
                title="Nouvelle Vente"
                to="/vente-alimentation"
                icon={<AddCircleOutline />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Historique des Ventes"
                to="/transaction-alimentation/history"
                icon={<HistoryOutlined />}
                selected={selected}
                setSelected={setSelected}
              />
            </SubMenu>
            <SubMenu
              title="Les ventes"
              icon={<PointOfSaleOutlinedIcon />}
              style={{
                color: selected === "Transaction" ? colors.blueAccent[500] : colors.grey[100],
              }}
            >
              <Item
                title="Vente cash"
                to="/transaction/sales"
                icon={<PointOfSaleOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Vente Banque"
                to="/transaction/banque"
                icon={<AccountBalanceOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Vente avec credit"
                to="/transaction/debt"
                icon={<CreditCardOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            </SubMenu>

            <Item
              title="Depot de l'argent"
              to="/retrait-depot"
              icon={<GroupOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title="Gestions utilisateurs"
              to="/team"
              icon={<GroupOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
          </Box>
        </Menu>
      </ProSidebar>

      {/* Toggle Button for small screens */}
      {isSmallScreen && (
        <Box position="fixed" bottom="20px" left="20px">
          <IconButton
            onClick={handleCollapse}
            color="primary"
          >
            <MenuOutlinedIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default Sidebar;
