import { useState } from 'react';
import axios from 'axios';
import { server } from '../server';

export const useInvoiceNumber = () => {
  const [loading, setLoading] = useState(false);

  const getInvoiceNumber = async (shopId) => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      };

      const { data } = await axios.get(`${server}/invoice/next/${shopId}`, config);
      return data.invoiceNumber;
    } catch (error) {
      console.error('Error fetching invoice number:', error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return { getInvoiceNumber, loading };
};