import { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Team from "./scenes/team";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Vente from "./scenes/vente";
import Produit from "./scenes/product";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProtectedRoute from "./routes/ProtectedRoute";
import { loadUser } from "./redux/actions/user";
import Store from "./redux/store";
import Login from "./components/login/Login";
import { useSelector } from "react-redux";
import ShopSelector from "./components/shopSelection/ShopSelection";
import ProductAlimentation from "./scenes/product/ProduitAlimentation";
import Depenses from "./scenes/depenses/index";
import RetraitDepot from "./scenes/depot/index"
import RoleProtectedRoute from "./components/RoleProtectedRoute"; // Import the custom component
import AccessDenied from "./components/AccessDenied"; // Import AccessDenied component
import VenteEnCash from "./scenes/transaction/venteEnCash";
import VenteParBank from "./scenes/transaction/venteParBank";
import VenteParCredit from "./scenes/transaction/venteParCredit";
import VenteAlimentation from "./scenes/vente/venteAlimentation";
import AlimentationPurchaseHistory from "./scenes/transaction/alimentationPurchaseHistory";

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    Store.dispatch(loadUser());
  }, []);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/*"
            element={
              <ProtectedRoute>
                <div className="app">
                  <Sidebar
                    isSidebar={isSidebar}
                    setIsCollapsed={setIsCollapsed}
                  />
                  <main
                    className="content"
                    style={{
                      marginLeft: isSidebar ? (isCollapsed ? "80px" : "270px") : "0px",
                      transition: "margin-left 0.3s ease",
                      overflowY: "auto",
                      height: "100vh",
                      padding: "20px",
                      width: "auto",
                    }}
                  >
                    <Topbar setIsSidebar={setIsSidebar} />
                    <Routes>
                      {/* Dashboard Route */}
                      <Route
                        path="/dashboard"
                        element={
                          <RoleProtectedRoute requiredRoles={["superadmin"]}>
                            <Dashboard />
                          </RoleProtectedRoute>
                        }
                      />

                      {/* Produit Route */}
                      <Route
                        path="/produit"
                        element={
                          <RoleProtectedRoute
                            checkAccess={(user) =>
                              user.role === "superadmin" ||
                              (user.shop && user.shop.type === "electronics")
                            }
                          >
                            <Produit />
                          </RoleProtectedRoute>
                        }
                      />

                      {/* tranaction Route */}
                      <Route
                        path="/transaction/sales"
                        element={
                          <RoleProtectedRoute
                            checkAccess={(user) =>
                              user.role === "superadmin" || user.role === "manager" ||
                              (user.shop && user.shop.type === "electronics")
                            }
                          >
                            <VenteEnCash />
                          </RoleProtectedRoute>
                        }
                      />
                      {/* tranaction Route */}
                      <Route
                        path="/transaction/banque"
                        element={
                          <RoleProtectedRoute
                            checkAccess={(user) =>
                              user.role === "superadmin" || user.role === "manager" ||
                              (user.shop && user.shop.type === "electronics")
                            }
                          >
                            <VenteParBank />
                          </RoleProtectedRoute>
                        }
                      />
                      {/* tranaction Route */}
                      <Route
                        path="/transaction/debt"
                        element={
                          <RoleProtectedRoute
                            checkAccess={(user) =>
                              user.role === "superadmin" || user.role === "manager" ||
                              (user.shop && user.shop.type === "electronics")
                            }
                          >
                            <VenteParCredit />
                          </RoleProtectedRoute>
                        }
                      />

                      {/* Produit Alimentation Route */}
                      <Route
                        path="/produit-alimentation"
                        element={
                          <RoleProtectedRoute
                            checkAccess={(user) => user.role === "superadmin" ||
                              user.shop && user.shop.type === "alimentation"
                            }
                          >
                            <ProductAlimentation />
                          </RoleProtectedRoute>
                        }
                      />

                      {/* Depenses Route */}
                      <Route path="/depenses" element={<Depenses />} />

                      {/* Deposits Route */}
                      <Route path="/retrait-depot" element={<RetraitDepot />} />

                      {/* Team Route */}
                      <Route
                        path="/team"
                        element={
                          <RoleProtectedRoute requiredRoles={["superadmin"]}>
                            <Team />
                          </RoleProtectedRoute>
                        }
                      />

                      {/* Other Routes */}
                      <Route
                        path="/vente"
                        element={
                          <RoleProtectedRoute
                            checkAccess={(user) =>
                              user.role === "superadmin" || user.role === "manager" ||
                              (user.shop && user.shop.type === "electronics")
                            }
                          >
                            <Vente />
                          </RoleProtectedRoute>
                        }
                      />

                      {/* Catch-all Route */}
                      <Route path="*" element={<AccessDenied />} />

                      {/* Add these new routes inside your existing Routes component */}
                      <Route
                        path="/vente-alimentation"
                        element={
                          <RoleProtectedRoute
                            checkAccess={(user) =>
                              user.role === "superadmin" ||
                              (user.shop && user.shop.type === "alimentation")
                            }
                          >
                            <VenteAlimentation />
                          </RoleProtectedRoute>
                        }
                      />

                      <Route
                        path="/transaction-alimentation/history"
                        element={
                          <RoleProtectedRoute
                            checkAccess={(user) =>
                              user.role === "superadmin" ||
                              (user.shop && user.shop.type === "alimentation")
                            }
                          >
                            <AlimentationPurchaseHistory />
                          </RoleProtectedRoute>
                        }
                      />

                      {/* Add similar routes for bank and credit sales */}
                    </Routes>
                  </main>
                </div>
              </ProtectedRoute>
            }
          />
        </Routes>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
