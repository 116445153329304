import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  shops: [],
  selectedShop: JSON.parse(localStorage.getItem("selectedShop")) || null,
  products: [],
  sales: [],
  loading: false,
  error: null,
};

export const shopReducer = createReducer(initialState, (builder) => {
  builder
    .addCase("FetchShopsRequest", (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase("FetchShopsSuccess", (state, action) => {
      state.loading = false;
      state.shops = action.payload;
      state.error = null;
    })
    .addCase("FetchShopsFail", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("SelectShopRequest", (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase("SelectShopSuccess", (state, action) => {
      state.loading = false;
      state.selectedShop = action.payload;
      state.error = null;
    })
    .addCase("SelectShopFail", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("FetchShopProductsRequest", (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase("FetchShopProductsSuccess", (state, action) => {
      state.loading = false;
      state.products = action.payload;
      state.error = null;
    })
    .addCase("FetchShopProductsFail", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("FetchShopSalesRequest", (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase("FetchShopSalesSuccess", (state, action) => {
      state.loading = false;
      state.sales = action.payload;
      state.error = null;
    })
    .addCase("FetchShopSalesFail", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("LogoutSuccess", (state) => {
      state.selectedShop = null;
      state.products = [];
      state.sales = [];
      localStorage.removeItem("selectedShop");
    });
});
