import { configureStore } from '@reduxjs/toolkit';
import { salesReducer } from './reducers/sales';
import { userReducer } from "./reducers/user";
import { shopReducer } from "./reducers/shop";
import { productReducer } from "./reducers/product";
import {productAlimentationReducer} from "./reducers/productAlimentation"
import {depenseReducer} from "./reducers/depense"
import { retraitDepotReducer } from './reducers/retraitDepot';
import { purchaseAlimentationReducer } from './reducers/purchaseAlimentation';

export const store = configureStore({
  reducer: {
    sales: salesReducer,
    user: userReducer,
    shop: shopReducer,
    product: productReducer,
    productAlimentation:productAlimentationReducer,
    depense:depenseReducer,
    retraitDepot:retraitDepotReducer,
    purchaseAlimentation: purchaseAlimentationReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;