// Produit.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  useTheme,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  CircularProgress,
  Typography,
  List,
  ListItem,
  ListItemText,
  Paper,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { Delete, Edit, Search, ShoppingCart } from "@mui/icons-material";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchProducts,
  fetchAllProducts,
  createProduct,
  updateProduct,
  deleteProduct,
  createAchatProduct,
  fetchAchatProducts,
  updateAchatProduct,
  deleteAchatProduct,
} from "../../redux/actions/product";
import { fetchAllShops, selectShop } from "../../redux/actions/shop";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';

const Produit = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { products, achatProducts, loading, error } = useSelector((state) => state.product);
  const { shops, selectedShop } = useSelector((state) => state.shop);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openAchat, setOpenAchat] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    // dispatch(fetchAllShops());
    // if (user?.role === 'superadmin') {
    //   dispatch(fetchProducts(selectedShop._id))
    // } else if (user?.shop?._id) {
    //   dispatch(fetchProducts(user.shop._id));
    // }
    const loadInitialData = async () => {
      try {
        if (!user) return;

        if (user.role === 'superadmin') {
          ;
          if (shops.length > 0) {
            const firstShop = electronicShops[0];
            if (firstShop) {
              dispatch(selectShop(firstShop));
              await dispatch(fetchProducts(firstShop._id));
            }
          }
        } else if (user.shop) {
          const shopId = typeof user.shop === 'object' ? user.shop._id : user.shop;
          if (shopId) {
            await dispatch(fetchProducts(shopId));
          }
        }
      } catch (error) {
        console.error('Error loading credit sales:', error);
        toast.error('Failed to load credit sales');
      }
    };

    loadInitialData();
  }, [dispatch, user, shops.length]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  useEffect(() => {
    if (selectedProduct) {
      dispatch(fetchAchatProducts(selectedProduct._id));
    }
  }, [selectedProduct, dispatch]);

  const filteredProducts = products.filter(product =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    product.category.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const columns = [
    { field: "createdAt", headerName: "Date", flex: 1, valueGetter: (params) => moment(params.row?.date).format('DD-MM-YYYY') },
    { field: "name", headerName: "Nom du produit", flex: 1.2, cellClassName: "name-column--cell" },
    { field: "category", headerName: "Categorie", flex: 1 },
    { field: "price_achat", headerName: "Prix d'achat", type: "number", flex: 0.5 },
    { field: "stock", headerName: "Quantité Stock", type: "number", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Box>
          <IconButton onClick={() => handleAchatOpen(params.row)} color="error">
            <ShoppingCart />
          </IconButton>
          {user.role === 'superadmin' && (
            <>
              <IconButton onClick={() => handleEditClick(params.row)} color="secondary">
                <Edit />
              </IconButton>
              <IconButton onClick={() => handleDelete(params.row._id)} color="error">
                <Delete />
              </IconButton>
            </>
          )}
        </Box>
      ),
    },
  ];

  const handleAddOpen = () => setOpenAdd(true);
  const handleAddClose = () => setOpenAdd(false);

  const handleEditOpen = () => setOpenEdit(true);
  const handleEditClose = () => setOpenEdit(false);

  const handleAchatOpen = (product) => {
    setSelectedProduct(product);
    setOpenAchat(true);
  };

  const handleAchatClose = () => {
    setOpenAchat(false);
    setSelectedProduct(null);
  };

  const handleAddProduct = async (productData) => {
    let shopId = null;

    if (user.role === 'superadmin') {
      if (!selectedShop) {
        toast.error("Please select a shop to add a product.");
        return;
      }
      shopId = selectedShop._id;
    } else {
      shopId = user.shop._id;
    }

    setIsSubmitting(true);
    const productToSubmit = {
      ...productData,
      price_achat: Number(productData.price_achat),
      stock: Number(productData.stock),
      shop: shopId,
    };

    try {
      await dispatch(createProduct(productToSubmit));
      handleAddClose();
      toast.success("Product added successfully!");
      if (user.role === 'superadmin') {
        dispatch(fetchProducts(selectedShop._id))
      } else {
        dispatch(fetchProducts(user.shop._id));
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleEditClick = (product) => {
    setSelectedProduct(product);
    handleEditOpen();
  };

  const handleEditProduct = async (productData) => {
    setIsSubmitting(true);
    const productToSubmit = {
      ...productData,
      price_achat: Number(productData.price_achat),
      stock: Number(productData.stock),
    };

    try {
      await dispatch(updateProduct(selectedProduct._id, productToSubmit));
      handleEditClose();
      toast.success("Product updated successfully!");
      if (user.role === 'superadmin') {
        dispatch(fetchProducts(selectedShop._id))
      } else {
        dispatch(fetchProducts(user.shop._id));
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this product?")) {
      try {
        await dispatch(deleteProduct(id));
        toast.success("Product deleted successfully!");
        if (user.role === 'superadmin') {
          dispatch(fetchProducts(selectedShop._id))
        } else {
          dispatch(fetchProducts(user.shop._id));
        }
      } catch (error) {
        toast.error(error);
      }
    }
  };

  const handleShopChange = (event) => {
    const shopId = event.target.value;
    if (shopId === 'all') {
      dispatch(selectShop(null));
    } else {
      const shop = shops.find(s => s._id === shopId);
      dispatch(selectShop(shop));
    }
    dispatch(fetchProducts(shopId === 'all' ? null : shopId));
  };

  const handleAchatSubmit = async (achatData) => {
    if (!achatData.quantity) {
      toast.error("Quantity is required.");
      return;
    }
    setIsSubmitting(true);
    try {
      await dispatch(createAchatProduct(selectedProduct._id, Number(achatData.quantity), achatData.description));
      toast.success("Achat product added successfully!");
      dispatch(fetchAchatProducts(selectedProduct._id));
      if (user.role === 'superadmin') {
        dispatch(fetchProducts(selectedShop._id))
      } else {
        dispatch(fetchProducts(user.shop._id));
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleAchatEdit = async (achatId) => {
    const newQuantity = prompt('Veuillez entrer la nouvelle quantité:');
    const newDescription = prompt('Veuillez entrer la description:');
    if (newQuantity !== null && newDescription !== null) {
      try {
        await dispatch(updateAchatProduct(achatId, Number(newQuantity), newDescription));
        toast.success("Achat product updated successfully!");
        dispatch(fetchAchatProducts(selectedProduct._id));
        if (user.role === 'superadmin') {
          dispatch(fetchProducts(selectedShop._id));
        } else {
          dispatch(fetchProducts(user.shop._id));
        }
      } catch (error) {
        toast.error(error);
      }
    }
  };

  const handleAchatDelete = async (achatId) => {
    if (window.confirm("Are you sure you want to delete this achat?")) {
      try {
        await dispatch(deleteAchatProduct(achatId));
        toast.success("Achat product deleted successfully!");
        dispatch(fetchAchatProducts(selectedProduct._id));
        if (user.role === 'superadmin') {
          dispatch(fetchProducts(selectedShop._id))
        } else {
          dispatch(fetchProducts(user.shop._id));
        }
      } catch (error) {
        toast.error(error);
      }
    }
  };

  const ProductForm = ({ initialProduct, isEdit, onSubmit, onCancel }) => {
    const [product, setProduct] = useState(initialProduct);

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setProduct(prev => ({ ...prev, [name]: value }));
    };

    const handleFormSubmit = () => {
      if (!product.name || !product.category) {
        toast.error("Name and Category are required.");
        return;
      }
      onSubmit(product);
    };

    return (
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="dense"
              name="name"
              label="Name"
              type="text"
              fullWidth
              variant="outlined"
              value={product.name}
              onChange={handleInputChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="dense" required>
              <InputLabel>Category</InputLabel>
              <Select
                name="category"
                value={product.category}
                onChange={handleInputChange}
                label="Category"
              >
                <MenuItem value="smartphone">smartphone</MenuItem>
                <MenuItem value="telephones">telephone</MenuItem>
                <MenuItem value="chargeurs">chargeur</MenuItem>
                <MenuItem value="ecouteurs">ecouteur</MenuItem>
                <MenuItem value="pochette">pochette</MenuItem>
                <MenuItem value="blendage">blendage</MenuItem>
                <MenuItem value="memoire">memoire</MenuItem>
                <MenuItem value="ecran-phone">ecran-phone</MenuItem>
                <MenuItem value="flash">flash</MenuItem>
                <MenuItem value="batterie">batterie</MenuItem>
                <MenuItem value="montre">montre</MenuItem>
                <MenuItem value="tete-chargeur">tete-chargeur</MenuItem>
                <MenuItem value="cable-chargeur">cable-chargeur</MenuItem>
                <MenuItem value="housing">housing</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="dense"
              name="price_achat"
              label="Price Achat"
              type="number"
              fullWidth
              variant="outlined"
              value={product.price_achat}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="dense"
              name="stock"
              label="Stock"
              type="number"
              fullWidth
              variant="outlined"
              value={product.stock}
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>
        <DialogActions>
          <Button onClick={onCancel}>
            Annuler
          </Button>
          <Button
            onClick={handleFormSubmit}
            color="secondary"
            variant="contained"
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={24} /> : isEdit ? "Enregister" : "Ajouter"}
          </Button>
        </DialogActions>
      </DialogContent>
    );
  };

  const AchatForm = ({ onSubmit }) => {
    const [achatData, setAchatData] = useState({ quantity: '', description: '' });

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setAchatData(prev => ({ ...prev, [name]: value }));
    };

    const handleFormSubmit = () => {
      onSubmit(achatData);
    };

    return (
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="dense"
              name="quantity"
              label="Quantity"
              type="number"
              fullWidth
              variant="outlined"
              value={achatData.quantity}
              onChange={handleInputChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="dense"
              name="description"
              label="Description"
              type="text"
              fullWidth
              variant="outlined"
              value={achatData.description}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              onClick={handleFormSubmit}
              color="secondary"
              variant="contained"
              disabled={isSubmitting}
              fullWidth
            >
              {isSubmitting ? <CircularProgress size={24} /> : "Ajouter l'Achat"}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    );
  };

  // Filter shops to display only shops with enum/type 'electronics'
  const electronicShops = shops.filter(shop => shop.enum === 'electronics' || shop.type === 'electronics');

  // Get the shop name from the logged-in user
  let shopName = '';
  if (user.role === 'superadmin') {
    shopName = selectedShop ? selectedShop.name : 'All Shops';
  } else if (user.shop) {
    shopName = user.shop.name;
  }

  return (
    <Box m="20px">
      <Header
        title={`PRODUITS DE LA BOUTIQUE - ${shopName}`}
        subtitle="Management des Produits"
      />
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Box display="flex" alignItems="center">
          <TextField
            label="recherche"
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
          {user.role === 'superadmin' && (
            <FormControl sx={{ minWidth: 120, ml: 2 }}>
              <InputLabel>Shop</InputLabel>
              <Select
                value={selectedShop ? selectedShop._id : 'all'}
                onChange={handleShopChange}
                label="Shop"
              >
                <MenuItem value="all">All Shops</MenuItem>
                {electronicShops.map((shop) => (
                  <MenuItem key={shop._id} value={shop._id}>{shop.name}</MenuItem>
                ))}

              </Select>
            </FormControl>
          )}
        </Box>

        <Button
          onClick={handleAddOpen}
          color="secondary"
          variant="contained"
        >
          Ajouter le produit
        </Button>

      </Box>

      {/* Add Product Dialog */}
      <Dialog open={openAdd} onClose={handleAddClose} maxWidth="md" fullWidth>
        <DialogTitle>Ajouter le produit</DialogTitle>
        <ProductForm
          initialProduct={{
            name: "",
            category: "",
            price_achat: "",
            stock: "",
          }}
          isEdit={false}
          onSubmit={handleAddProduct}
          onCancel={handleAddClose}
        />
      </Dialog>

      {/* Edit Product Dialog */}
      <Dialog open={openEdit} onClose={handleEditClose} maxWidth="md" fullWidth>
        <DialogTitle>Editer le produit</DialogTitle>
        {selectedProduct && (
          <ProductForm
            initialProduct={selectedProduct}
            isEdit={true}
            onSubmit={handleEditProduct}
            onCancel={handleEditClose}
          />
        )}
      </Dialog>

      {/* Achat Product Dialog */}
      <Dialog open={openAchat} onClose={handleAchatClose} maxWidth="md" fullWidth>
        <DialogTitle>Achat du produit - <strong>{selectedProduct?.name}</strong></DialogTitle>
        <AchatForm onSubmit={handleAchatSubmit} />
        <Typography variant="h6" style={{ marginTop: '20px', marginLeft: '20px' }}>Historique des achats sur le produit: <strong>{selectedProduct?.name}</strong></Typography>
        <DialogContent>
          <Paper style={{ maxHeight: 300, overflow: 'auto', marginTop: '10px' }}>
            <List>
              {achatProducts.map((achat) => (
                <ListItem key={achat._id}>
                  <ListItemText
                    primary={`Quantity: ${achat.quantity}`}
                    secondary={
                      <>
                        <Typography component="span" variant="body2" color="textPrimary">
                          Date: {moment(achat.date).format('DD-MM-YYYY')}
                        </Typography>
                        <br />
                        <Typography component="span" variant="body2" color="textPrimary">
                          User: {achat.user.name}
                        </Typography>
                        {achat.description && (
                          <>
                            <br />
                            <Typography component="span" variant="body2" color="textPrimary">
                              Description: {achat.description}
                            </Typography>
                          </>
                        )}
                      </>
                    }
                  />
                  {user.role === 'superadmin' && (
                    <>
                      <IconButton onClick={() => handleAchatEdit(achat._id)} color="secondary">
                        <Edit />
                      </IconButton>
                      <IconButton onClick={() => handleAchatDelete(achat._id)} color="error">
                        <Delete />
                      </IconButton>
                    </>)}
                </ListItem>
              ))}
            </List>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAchatClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Box
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
            color: colors.grey[100],
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
            color: colors.grey[100],
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            color: colors.grey[100],
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={filteredProducts}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          loading={loading}
          getRowId={(row) => row._id}
        />
      </Box>
    </Box>
  );
};

export default Produit;
