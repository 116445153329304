import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ProductDetailsDialog = ({ open, onClose, products }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        Details du produit
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nom</TableCell>
                <TableCell>Categorie</TableCell>
                <TableCell>Prix d'achat(Fbu)</TableCell>
                <TableCell>Prix de vente(Fbu)</TableCell>
                <TableCell align="right">Bénéfice (Fbu)</TableCell>
                <TableCell align="right">Quantité</TableCell>
                <TableCell align="right">Total (Fbu)</TableCell>
                <TableCell align="right">T.Bénéfice (Fbu)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products?.map((product, index) => (
                <TableRow key={index}>
                  <TableCell>{product.name}</TableCell>
                  <TableCell>{product.category}</TableCell>
                  <TableCell>{product.price_achat?.toLocaleString()}</TableCell>
                  <TableCell>{product.price?.toLocaleString()}</TableCell>
                  <TableCell align="right">{(product.price - product.price_achat).toLocaleString()}</TableCell>
                  <TableCell align="right">{product.quantity}</TableCell>
                  <TableCell align="right">{(product.quantity * product.price).toLocaleString()}</TableCell>
                  <TableCell align="right">{((product.price - product.price_achat) * product.quantity).toLocaleString()}</TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell colSpan={7} align="right">Total Bénéfice (Fbu)</TableCell>
                <TableCell align="right">
                  {products?.reduce((total, product) => total + ((product.price - product.price_achat) * product.quantity), 0).toLocaleString()}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
};

export default ProductDetailsDialog; 