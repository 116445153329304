// redux/actions/depense.js

import axios from 'axios';
import { server } from "../../server";

const getConfig = () => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  };
};

// Fetch Depenses
export const fetchDepenses = (shopId) => async (dispatch) => {
  dispatch({ type: "FetchDepensesRequest" });
  try {
    let url = `${server}/depenses/depenses`;
    if (shopId) {
      url += `?shopId=${shopId}`;
    }
    const { data } = await axios.get(url, getConfig());
    dispatch({ type: "FetchDepensesSuccess", payload: data.depenses });
  } catch (error) {
    dispatch({ type: "FetchDepensesFailure", payload: error.response?.data?.message || 'An error occurred' });
  }
};

// Create Depense
export const createDepense = (depenseData) => async (dispatch) => {
  dispatch({ type: "CreateDepenseRequest" });
  try {
    const { data } = await axios.post(
      `${server}/depenses/depenses`,
      depenseData,
      getConfig()
    );
    dispatch({ type: "CreateDepenseSuccess", payload: data.depense });
    return Promise.resolve(data.depense);
  } catch (error) {
    dispatch({
      type: "CreateDepenseFailure",
      payload: error.response?.data?.message || 'An error occurred'
    });
    return Promise.reject(error.response?.data?.message || 'An error occurred');
  }
};

// Update Depense (superadmin only)
export const updateDepense = (depenseId, depenseData) => async (dispatch) => {
  dispatch({ type: "UpdateDepenseRequest" });
  try {
    const { data } = await axios.put(`${server}/depenses/depenses/${depenseId}`, depenseData, getConfig());
    dispatch({ type: "UpdateDepenseSuccess", payload: data.depense });
    return Promise.resolve(data.depense);
  } catch (error) {
    dispatch({ type: "UpdateDepenseFailure", payload: error.response?.data?.message || 'An error occurred' });
    return Promise.reject(error.response?.data?.message || 'An error occurred');
  }
};

// Delete Depense (superadmin only)
export const deleteDepense = (depenseId) => async (dispatch) => {
  dispatch({ type: "DeleteDepenseRequest" });
  try {
    await axios.delete(`${server}/depenses/depenses/${depenseId}`, getConfig());
    dispatch({ type: "DeleteDepenseSuccess", payload: depenseId });
    return Promise.resolve();
  } catch (error) {
    dispatch({ type: "DeleteDepenseFailure", payload: error.response?.data?.message || 'An error occurred' });
    return Promise.reject(error.response?.data?.message || 'An error occurred');
  }
};

// Fetch Daily Depenses per Shop
export const fetchDailyDepenses = (shopId) => async (dispatch) => {
  dispatch({ type: "FetchDailyDepensesRequest" });
  try {
    const { data } = await axios.get(`${server}/depenses/depenses/daily/${shopId}`, getConfig());
    dispatch({ type: "FetchDailyDepensesSuccess", payload: data });
    return Promise.resolve(data);
  } catch (error) {
    dispatch({ type: "FetchDailyDepensesFailure", payload: error.response?.data?.message || 'An error occurred' });
    return Promise.reject(error.response?.data?.message || 'An error occurred');
  }
};

// Fetch Monthly Depenses per Shop
export const fetchMonthlyDepenses = (shopId) => async (dispatch) => {
  dispatch({ type: "FetchMonthlyDepensesRequest" });
  try {
    const { data } = await axios.get(`${server}/depenses/depenses/monthly/${shopId}`, getConfig());
    dispatch({ type: "FetchMonthlyDepensesSuccess", payload: data });
    return Promise.resolve(data);
  } catch (error) {
    dispatch({ type: "FetchMonthlyDepensesFailure", payload: error.response?.data?.message || 'An error occurred' });
    return Promise.reject(error.response?.data?.message || 'An error occurred');
  }
};

// Fetch Total Daily Depenses by Shop Type
export const fetchDailyTotalDepenses = () => async (dispatch) => {
  dispatch({ type: "FetchDailyTotalDepensesRequest" });
  try {
    const { data } = await axios.get(`${server}/depenses/depenses/daily-total`, getConfig());
    dispatch({ type: "FetchDailyTotalDepensesSuccess", payload: data.depensesByType });
    return Promise.resolve(data.depensesByType);
  } catch (error) {
    dispatch({ type: "FetchDailyTotalDepensesFailure", payload: error.response?.data?.message || 'An error occurred' });
    return Promise.reject(error.response?.data?.message || 'An error occurred');
  }
};

// Fetch Total Monthly Depenses by Shop Type
export const fetchMonthlyTotalDepenses = () => async (dispatch) => {
  dispatch({ type: "FetchMonthlyTotalDepensesRequest" });
  try {
    const { data } = await axios.get(`${server}/depenses/depenses/monthly-total`, getConfig());
    dispatch({ type: "FetchMonthlyTotalDepensesSuccess", payload: data.depensesByType });
    return Promise.resolve(data.depensesByType);
  } catch (error) {
    dispatch({ type: "FetchMonthlyTotalDepensesFailure", payload: error.response?.data?.message || 'An error occurred' });
    return Promise.reject(error.response?.data?.message || 'An error occurred');
  }
};
