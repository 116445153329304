import React, { useEffect, useState } from "react";
import {
  Container,
  TextField,
  Typography,
  Button,
  Grid,
  Box,
  MenuItem,
  IconButton,
  Paper,
  Divider,
  FormControl,
  InputLabel,
  Select,
  Autocomplete,
} from "@mui/material";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { createSale } from "../../redux/actions/sales";
import { fetchAllShops, fetchShopProducts, selectShop } from "../../redux/actions/shop";
import { toast } from "react-toastify";
import { fetchDebtClients } from "../../redux/actions/sales";
import { useNavigate } from "react-router-dom";

const PurchaseForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const { selectedShop, products, shops } = useSelector((state) => state.shop);
  const { debtClients } = useSelector((state) => state.sales);

  const [sale, setSale] = useState({
    shop: user?.role === 'superadmin' ? '' : (typeof user?.shop === 'object' ? user.shop._id : user.shop),
    client_name: "",
    client_phone: "",
    client_address: "",
    products: [
      {
        product: "",
        category: "",
        price_achat: 0,
        quantity: 1,
        price: '',
      },
    ],
    total_amount: 0,
    payment_method: "cash",
    amount_paid: 0,
  });

  const [shopName, setShopName] = useState("");
  const [isNewClient, setIsNewClient] = useState(true);

  useEffect(() => {
    if (user?.role === 'superadmin') {
      dispatch(fetchAllShops());
      if (selectedShop) {
        setSale(prev => ({ ...prev, shop: selectedShop._id }));
        dispatch(fetchShopProducts(selectedShop._id));
        setShopName(selectedShop.name);
      }
    } else if (user?.shop) {
      const shopId = typeof user.shop === 'object' ? user.shop._id : user.shop;
      dispatch(fetchShopProducts(shopId));
      setSale(prev => ({ ...prev, shop: shopId }));
      setShopName(typeof user.shop === 'object' ? user.shop.name : '');
    }
  }, [user]);

  useEffect(() => {
    if (selectedShop && user?.role === 'superadmin') {
      setSale(prev => ({ ...prev, shop: selectedShop._id }));
      dispatch(fetchShopProducts(selectedShop._id));
      setShopName(selectedShop.name);
    }
  }, [selectedShop]);

  useEffect(() => {
    if (sale.payment_method === 'debt' && sale.shop) {
      dispatch(fetchDebtClients(sale.shop));
    }
  }, [sale.payment_method, sale.shop]);

  const handleProductChange = (index, productId) => {
    const updatedProducts = [...sale.products];
    const selectedProduct = products.find((product) => product._id === productId);

    if (selectedProduct) {
      updatedProducts[index] = {
        ...updatedProducts[index],
        product: productId,
        name: selectedProduct.name,
        category: selectedProduct.category,
        price_achat: selectedProduct.price_achat,
        quantity: updatedProducts[index].quantity || 1,
      };

      setSale({ ...sale, products: updatedProducts });
      calculateTotalAmount(updatedProducts);
    }
  };

  const handleQuantityChange = (index, quantity) => {
    const updatedProducts = [...sale.products];
    updatedProducts[index].quantity = Number(quantity); // Ensure quantity is a valid number
    setSale({ ...sale, products: updatedProducts });
    calculateTotalAmount(updatedProducts);
  };

  const calculateTotalAmount = (products) => {
    const total = products.reduce((acc, item) => {
      const itemTotal = (Number(item.quantity) || 0) * (Number(item.price) || 0);
      return acc + itemTotal;
    }, 0);
    setSale((prevSale) => ({ ...prevSale, total_amount: total }));
  };

  const addProduct = () => {
    setSale({
      ...sale,
      products: [
        ...sale.products,
        {
          product: "",
          category: "",
          price_achat: 0,
          quantity: 1,
          price: 0,
        },
      ],
    });
  };

  const removeProduct = (index) => {
    const updatedProducts = sale.products.filter((_, i) => i !== index);
    setSale({ ...sale, products: updatedProducts });
    calculateTotalAmount(updatedProducts);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      let shopId;
      if (user.role === 'superadmin') {
        shopId = selectedShop?._id;
      } else {
        shopId = typeof user.shop === 'object' ? user.shop._id : user.shop;
      }

      if (!shopId) {
        toast.error("Veuillez sélectionner un shop");
        return;
      }

      if (!sale.client_name.trim()) {
        toast.error("Nom du client est requis");
        return;
      }

      if (!sale.client_phone.trim()) {
        toast.error("Téléphone du client est requis");
        return;
      }

      if (sale.products.length === 0) {
        toast.error("Veuillez ajouter au moins un produit");
        return;
      }

      for (const product of sale.products) {
        if (!product.product) {
          toast.error("Veuillez sélectionner un produit pour tous les éléments");
          return;
        }
        if (!product.quantity || product.quantity <= 0) {
          toast.error("La quantité doit être supérieure à 0 pour tous les produits");
          return;
        }
      }

      if (sale.amount_paid < 0) {
        toast.error("Le montant payé ne peut pas être négatif");
        return;
      }

      // Add the validation for cash and bank payment methods
      if (sale.payment_method === 'cash' || sale.payment_method === 'bank') {
        if (sale.amount_paid !== sale.total_amount) {
          toast.error("Le montant payé doit être égal au montant total pour les paiements en espèces ou par banque");
          return;
        }
      }

      if (sale.amount_paid > sale.total_amount) {
        toast.error("Le montant payé ne peut pas dépasser le montant total");
        return;
      }

      const saleData = {
        ...sale,
        shop_id: shopId,
      };

      const result = await dispatch(createSale(saleData));

      if (result && result.success) {
        toast.success("Vente créée avec succès");

        switch (sale.payment_method) {
          case 'cash':
            navigate('/transaction/sales');
            break;
          case 'bank':
            navigate('/transaction/banque');
            break;
          case 'debt':
            navigate('/transaction/debt');
            break;
        }

        setSale({
          shop_id: shopId,
          client_name: "",
          client_phone: "",
          client_address: "",
          products: [
            {
              product: "",
              category: "",
              quantity: 1,
              price_achat: 0,
              price: 0,
            },
          ],
          total_amount: 0,
          payment_method: "cash",
          amount_paid: 0,
        });
        setIsNewClient(true);
      }
    } catch (error) {
      console.error('Error creating sale:', error);
      toast.error(error?.response?.data?.message || "Erreur lors de la création de la vente");
    }
  };

  const handleShopChange = (event) => {
    const shopId = event.target.value;
    const shop = shops.find(s => s._id === shopId);
    dispatch(selectShop(shop));
    dispatch(fetchShopProducts(shopId));
  };

  const electronicShops = shops.filter(shop =>
    shop.enum === 'electronics' || shop.type === 'electronics'
  );

  const handlePaymentMethodChange = (e) => {
    const method = e.target.value;
    setSale(prev => ({
      ...prev,
      payment_method: method,
      amount_paid: method === 'debt' ? 0 : prev.amount_paid
    }));
  };



  return (
    <Container maxWidth="lg">
      <Paper elevation={3} sx={{ padding: 3, borderRadius: 2, mt: 4 }}>
        <Typography variant="h4" align="center" gutterBottom>
          Vente des produit
        </Typography>
        <Divider sx={{ mb: 3 }} />
        <Box component="form" onSubmit={handleSubmit} noValidate>
          <Grid container spacing={2}>
            {/* Shop Selection */}
            <Grid item xs={12} md={4}>
              {user?.role === 'superadmin' ? (
                <FormControl fullWidth>
                  <InputLabel>Sélectionner un Shop</InputLabel>
                  <Select
                    value={selectedShop?._id || ''}
                    onChange={handleShopChange}
                    label="Sélectionner un Shop"
                    required
                  >
                    {electronicShops.map((shop) => (
                      <MenuItem key={shop._id} value={shop._id}>
                        {shop.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <TextField
                  label="Nom du Shop*"
                  value={shopName}
                  fullWidth
                  disabled
                  variant="outlined"
                />
              )}
            </Grid>

            {/* Payment Method */}
            <Grid item xs={12} md={4}>
              <TextField
                label="Mode de paiement*"
                value={sale.payment_method}
                onChange={handlePaymentMethodChange}
                fullWidth
                select
                required
                variant="outlined"
              >
                <MenuItem value="cash">Cash</MenuItem>
                <MenuItem value="bank">Bank</MenuItem>
                <MenuItem value="debt">Credit</MenuItem>
              </TextField>
            </Grid>

            {/* Client Details */}
            <Grid item xs={12} md={4}>
              <TextField
                label="Nom du client*"
                value={sale.client_name}
                onChange={(e) => setSale(prev => ({ ...prev, client_name: e.target.value }))}
                fullWidth
                required
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                label="Téléphone du client*"
                value={sale.client_phone}
                onChange={(e) => setSale(prev => ({ ...prev, client_phone: e.target.value }))}
                fullWidth
                required
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                label="Adresse du client*"
                value={sale.client_address}
                onChange={(e) => setSale(prev => ({ ...prev, client_address: e.target.value }))}
                fullWidth
                required
                variant="outlined"
              />
            </Grid>
          </Grid>

          <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
            Products
          </Typography>

          {sale.products.map((item, index) => (
            <Paper key={index} elevation={2} sx={{ p: 2, mt: 2, borderRadius: 1 }}>
              <Grid container spacing={2} alignItems="center">
                {/* Product Selection */}
                <Grid item xs={12} sm={6} md={3}>
                  <Autocomplete
                    value={products.find(p => p._id === item.product) || null}
                    onChange={(e, newValue) => {
                      if (newValue) {
                        handleProductChange(index, newValue._id);
                      }
                    }}
                    options={products}
                    getOptionLabel={(option) => option.name || ''}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Produit*"
                        required
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </Grid>

                {/* Category */}
                <Grid item xs={12} sm={6} md={2}>
                  <TextField
                    label="Catégorie"
                    value={item.category}
                    fullWidth
                    disabled
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={2}>
                  <TextField
                    label="Prix d'achat"
                    value={item.price_achat?.toLocaleString()}
                    fullWidth
                    disabled
                    variant="outlined"
                  />
                </Grid>

                {/* Quantity */}
                <Grid item xs={12} sm={6} md={2}>
                  <TextField
                    label="Quantité"
                    type="number"
                    value={item.quantity}
                    onChange={(e) => handleQuantityChange(index, parseInt(e.target.value))}
                    fullWidth
                    required
                    variant="outlined"
                  />
                </Grid>

                {/* Price */}
                <Grid item xs={12} sm={6} md={2}>
                  <TextField
                    label="Prix"
                    type="number"
                    value={item.price}
                    onChange={(e) => {
                      const price = e.target.value;
                      const updatedProducts = [...sale.products];
                      updatedProducts[index].price = parseInt(price);
                      setSale({ ...sale, products: updatedProducts });
                      calculateTotalAmount(updatedProducts);
                    }}
                    fullWidth
                    required
                    variant="outlined"
                  />
                </Grid>

                {/* Add/Remove Buttons */}
                <Grid item xs={12} sm={6} md={1}>
                  <Box display="flex" justifyContent="center">
                    <IconButton
                      onClick={() => removeProduct(index)}
                      color="error"
                      disabled={sale.products.length === 1}
                    >
                      <RemoveCircleOutline />
                    </IconButton>
                    {index === sale.products.length - 1 && (
                      <IconButton onClick={addProduct} color="secondary">
                        <AddCircleOutline />
                      </IconButton>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          ))}

          {/* Payment Details */}
          <Grid container spacing={2} sx={{ mt: 3 }}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Montant payé"
                type="number"
                value={sale.amount_paid}
                onChange={(e) => setSale(prev => ({ ...prev, amount_paid: parseFloat(e.target.value) }))}
                fullWidth
                required
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper elevation={2} sx={{ p: 2, textAlign: "right" }}>
                <Typography variant="h6">
                  Montant total: <strong>{sale.total_amount} Fbu</strong>
                </Typography>
              </Paper>
            </Grid>
          </Grid>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            size="large"
            sx={{ mt: 4 }}
          >
            Vendre
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default PurchaseForm;
