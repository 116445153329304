import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  depenses: [],
  dailyDepenses: null,
  monthlyDepenses: null,
  dailyTotalDepenses: null,
  monthlyTotalDepenses: null,
  loading: false,
  error: null,
};

export const depenseReducer = createReducer(initialState, (builder) => {
  builder
    // Fetch Depenses
    .addCase("FetchDepensesRequest", (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase("FetchDepensesSuccess", (state, action) => {
      state.loading = false;
      state.depenses = action.payload;
      state.error = null;
    })
    .addCase("FetchDepensesFailure", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    // Create Depense
    .addCase("CreateDepenseRequest", (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase("CreateDepenseSuccess", (state, action) => {
      state.loading = false;
      state.depenses.unshift(action.payload); // Add new depense at the beginning
      state.error = null;
    })
    .addCase("CreateDepenseFailure", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    // Update Depense
    .addCase("UpdateDepenseRequest", (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase("UpdateDepenseSuccess", (state, action) => {
      state.loading = false;
      const index = state.depenses.findIndex(
        (depense) => depense._id === action.payload._id
      );
      if (index !== -1) {
        state.depenses[index] = action.payload;
      }
      state.error = null;
    })
    .addCase("UpdateDepenseFailure", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    // Delete Depense
    .addCase("DeleteDepenseRequest", (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase("DeleteDepenseSuccess", (state, action) => {
      state.loading = false;
      state.depenses = state.depenses.filter(
        (depense) => depense._id !== action.payload
      );
      state.error = null;
    })
    .addCase("DeleteDepenseFailure", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    // Fetch Daily Depenses
    .addCase("FetchDailyDepensesRequest", (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase("FetchDailyDepensesSuccess", (state, action) => {
      state.loading = false;
      state.dailyDepenses = action.payload;
      state.error = null;
    })
    .addCase("FetchDailyDepensesFailure", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    // Fetch Monthly Depenses
    .addCase("FetchMonthlyDepensesRequest", (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase("FetchMonthlyDepensesSuccess", (state, action) => {
      state.loading = false;
      state.monthlyDepenses = action.payload;
      state.error = null;
    })
    .addCase("FetchMonthlyDepensesFailure", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    // Fetch Daily Total Depenses
    .addCase("FetchDailyTotalDepensesRequest", (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase("FetchDailyTotalDepensesSuccess", (state, action) => {
      state.loading = false;
      state.dailyTotalDepenses = action.payload;
      state.error = null;
    })
    .addCase("FetchDailyTotalDepensesFailure", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    // Fetch Monthly Total Depenses
    .addCase("FetchMonthlyTotalDepensesRequest", (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase("FetchMonthlyTotalDepensesSuccess", (state, action) => {
      state.loading = false;
      state.monthlyTotalDepenses = action.payload;
      state.error = null;
    })
    .addCase("FetchMonthlyTotalDepensesFailure", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
});

export default depenseReducer;
