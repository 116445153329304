import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  useTheme,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  CircularProgress,
  Typography,
  Card,
  CardContent,
  DialogContent,
  DialogActions,
  Paper,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { Delete, Edit, Search, AccountBalance, TrendingUp, TrendingDown } from "@mui/icons-material";
import { useSelector, useDispatch } from "react-redux";
import {
  createTransaction,
  fetchTransactions,
  updateTransaction,
  deleteTransaction,
} from "../../redux/actions/retraitDepot";
import { fetchAllShops, selectShop } from "../../redux/actions/shop";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';

const TransactionForm = ({ initialData, onSubmit, onCancel }) => {
  const [formData, setFormData] = useState(initialData || {
    type: 'deposit',
    amount: '',
    method: 'CASH',
    description: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.amount || !formData.method) {
      toast.error("Veuillez remplir tous les champs obligatoires");
      return;
    }
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit}>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Type</InputLabel>
              <Select
                name="type"
                value={formData.type}
                onChange={handleChange}
                label="Type"
                required
              >
                <MenuItem value="deposit">Dépôt</MenuItem>
                <MenuItem value="withdrawal">Retrait</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Montant"
              name="amount"
              type="number"
              value={formData.amount}
              onChange={handleChange}
              required
              InputProps={{
                endAdornment: <InputAdornment position="end">BIF</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Méthode</InputLabel>
              <Select
                name="method"
                value={formData.method}
                onChange={handleChange}
                label="Méthode"
                required
              >
                <MenuItem value="CASH">CASH</MenuItem>
                <MenuItem value="KCB">KCB</MenuItem>
                <MenuItem value="BCB">BCB</MenuItem>
                <MenuItem value="BANCOBU">BANCOBU</MenuItem>
                <MenuItem value="IBB">IBB</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Description"
              name="description"
              multiline
              rows={3}
              value={formData.description}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Annuler</Button>
        <Button type="submit" variant="contained" color="secondary">
          {initialData ? 'Modifier' : 'Ajouter'}
        </Button>
      </DialogActions>
    </form>
  );
};

const RetraitDepot = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { transactions, summaries, loading } = useSelector((state) => state.retraitDepot);
  const { shops, selectedShop } = useSelector((state) => state.shop);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dateFilter, setDateFilter] = useState({
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD')
  });

  useEffect(() => {
    if (user.role === 'superadmin') {
      dispatch(fetchAllShops());
    }
    loadTransactions();
  }, [dispatch, user, selectedShop]);

  const loadTransactions = () => {
    const filters = {
      ...(selectedShop && selectedShop._id !== 'all' && { shop: selectedShop._id }),
      ...(dateFilter.startDate && { startDate: dateFilter.startDate }),
      ...(dateFilter.endDate && { endDate: dateFilter.endDate })
    };
    dispatch(fetchTransactions(filters));
  };

  const handleAddOpen = () => setOpenAdd(true);
  const handleAddClose = () => setOpenAdd(false);
  const handleEditOpen = () => setOpenEdit(true);
  const handleEditClose = () => {
    setOpenEdit(false);
    setSelectedTransaction(null);
  };

  const handleShopChange = (event) => {
    const shopId = event.target.value;
    if (shopId === 'all') {
      dispatch(selectShop(null)); // Clear selected shop
    } else {
      const shop = shops.find(s => s._id === shopId);
      dispatch(selectShop(shop));
    }
    loadTransactions(); // Refresh transactions based on shop selection
  };

  const handleSubmit = async (transactionData) => {
    setIsSubmitting(true);
    try {
      const shopId = user.role === 'superadmin' 
        ? (selectedShop && selectedShop._id !== 'all' ? selectedShop._id : null)
        : user.shop._id;
        
      if (user.role === 'superadmin' && !shopId) {
        toast.error("Veuillez sélectionner une boutique spécifique pour ajouter une transaction");
        return;
      }

      await dispatch(createTransaction({
        ...transactionData,
        shop: shopId
      }));
      handleAddClose();
      toast.success("Transaction créée avec succès!");
      loadTransactions();
    } catch (error) {
      toast.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleEditClick = (transaction) => {
    setSelectedTransaction(transaction);
    handleEditOpen();
  };

  const handleEditSubmit = async (transactionData) => {
    setIsSubmitting(true);
    try {
      await dispatch(updateTransaction(selectedTransaction._id, transactionData));
      handleEditClose();
      toast.success("Transaction mise à jour avec succès!");
      loadTransactions();
    } catch (error) {
      toast.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer cette transaction?")) {
      try {
        await dispatch(deleteTransaction(id));
        toast.success("Transaction supprimée avec succès!");
        loadTransactions();
      } catch (error) {
        toast.error(error);
      }
    }
  };

  const filteredTransactions = transactions.filter(transaction =>
    transaction.description?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    transaction.method.toLowerCase().includes(searchTerm.toLowerCase()) ||
    transaction.shop?.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const columns = [
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      valueGetter: (params) => moment(params.row.date).format('DD-MM-YYYY HH:mm')
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      renderCell: (params) => (
        <Box display="flex" alignItems="center" gap={1}>
          {params.row.type === 'deposit' ? (
            <TrendingUp style={{ color: colors.greenAccent[500] }} />
          ) : (
            <TrendingDown style={{ color: colors.redAccent[500] }} />
          )}
          <Typography color={params.row.type === 'deposit' ? colors.greenAccent[500] : colors.redAccent[500]}>
            {params.row.type === 'deposit' ? 'Dépôt' : 'Retrait'}
          </Typography>
        </Box>
      )
    },
    {
      field: "amount",
      headerName: "Montant",
      flex: 1,
      renderCell: (params) => (
        <Typography color={params.row.type === 'deposit' ? colors.greenAccent[500] : colors.redAccent[500]}>
          {params.row.amount.toLocaleString()} BIF
        </Typography>
      )
    },
    { field: "method", headerName: "Méthode", flex: 1 },
    {
      field: "shop",
      headerName: "Boutique",
      flex: 1,
      valueGetter: (params) => params.row.shop?.name
    },
    {
      field: "user",
      headerName: "Utilisateur",
      flex: 1,
      valueGetter: (params) => params.row.user?.name
    },
    { field: "description", headerName: "Description", flex: 2 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Box>
          {user.role === 'superadmin' && (
            <>
              <IconButton onClick={() => handleEditClick(params.row)} color="secondary">
                <Edit />
              </IconButton>
              <IconButton onClick={() => handleDelete(params.row._id)} color="error">
                <Delete />
              </IconButton>
            </>
          )}
        </Box>
      ),
    },
  ];

  const shopName = user.role === 'superadmin'
    ? (selectedShop ? selectedShop.name : 'Toutes les boutiques')
    : user.shop?.name || '';

  return (
    <Box m="20px">
      <Header
        title={`RETRAIT/DÉPÔT - ${shopName}`}
        subtitle="Gestion des retraits et dépôts"
      />

      {/* Summary Cards */}
      <Grid container spacing={2} mb={3}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" color={colors.grey[100]} gutterBottom>
                Aujourd'hui
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" color={colors.greenAccent[500]}>
                    Dépôts: {summaries?.daily?.deposits?.toLocaleString() || 0} BIF
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" color={colors.redAccent[500]}>
                    Retraits: {summaries?.daily?.withdrawals?.toLocaleString() || 0} BIF
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h4" color={colors.grey[100]}>
                    Solde: {summaries?.daily?.total?.toLocaleString() || 0} BIF
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" color={colors.grey[100]} gutterBottom>
                Ce Mois
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" color={colors.greenAccent[500]}>
                    Dépôts: {summaries?.monthly?.deposits?.toLocaleString() || 0} BIF
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" color={colors.redAccent[500]}>
                    Retraits: {summaries?.monthly?.withdrawals?.toLocaleString() || 0} BIF
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h4" color={colors.grey[100]}>
                    Solde: {summaries?.monthly?.total?.toLocaleString() || 0} BIF
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Controls */}
      <Paper sx={{ p: 2, mb: 2 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              label="Recherche"
              variant="outlined"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          {user.role === 'superadmin' && (
            <Grid item xs={12} md={2}>
              <FormControl fullWidth>
                <InputLabel>Boutique</InputLabel>
                <Select
                  value={selectedShop?._id || 'all'}
                  onChange={handleShopChange}
                  label="Boutique"
                >
                  {/* <MenuItem value="all">Toutes les boutiques</MenuItem> */}
                  {shops.map((shop) => (
                    <MenuItem key={shop._id} value={shop._id}>{shop.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={12} md={2}>
            <TextField
              fullWidth
              label="Date début"
              type="date"
              value={dateFilter.startDate}
              onChange={(e) => setDateFilter(prev => ({ ...prev, startDate: e.target.value }))}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              fullWidth
              label="Date fin"
              type="date"
              value={dateFilter.endDate}
              onChange={(e) => setDateFilter(prev => ({ ...prev, endDate: e.target.value }))}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} md={1}>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              onClick={loadTransactions}
            >
              Filtrer
            </Button>
          </Grid>
          <Grid item xs={12} md={2}>
            <Button
              fullWidth
              onClick={handleAddOpen}
              color="secondary"
              variant="contained"
              startIcon={<AccountBalance />}
              disabled={user.role === 'superadmin' && (!selectedShop || selectedShop._id === 'all')}
            >
              Nouvelle Transaction
            </Button>
          </Grid>
        </Grid>
      </Paper>

      {/* Add Transaction Dialog */}
      <Dialog open={openAdd} onClose={handleAddClose} maxWidth="md" fullWidth>
        <DialogTitle>
          Nouvelle Transaction
          {user.role === 'superadmin' && selectedShop && selectedShop._id !== 'all' && (
            <Typography variant="subtitle1" color="textSecondary">
              Boutique: {selectedShop.name}
            </Typography>
          )}
        </DialogTitle>
        <TransactionForm onSubmit={handleSubmit} onCancel={handleAddClose} />
      </Dialog>

      {/* Edit Transaction Dialog */}
      <Dialog open={openEdit} onClose={handleEditClose} maxWidth="md" fullWidth>
        <DialogTitle>Modifier la Transaction</DialogTitle>
        {selectedTransaction && (
          <TransactionForm
            initialData={selectedTransaction}
            onSubmit={handleEditSubmit}
            onCancel={handleEditClose}
          />
        )}
      </Dialog>

      {/* Transactions Grid */}
      <Box
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={filteredTransactions}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          loading={loading}
          getRowId={(row) => row._id}
        />
      </Box>
    </Box>
  );
};

export default RetraitDepot;
