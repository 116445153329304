import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Grid,
  Paper,
  Typography,
  MenuItem,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  Container,
  Autocomplete,
} from "@mui/material";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { createPurchaseAlimentation } from "../../redux/actions/purchaseAlimentation";
import { fetchProductsAlimentation } from "../../redux/actions/productAlimentation";
import { toast } from "react-toastify";
import { fetchClientsWithDebt } from "../../redux/actions/purchaseAlimentation";
import { fetchAllShops } from "../../redux/actions/shop";
import { useNavigate } from "react-router-dom";

const VenteAlimentation = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { productsAlimentation } = useSelector(
    (state) => state.productAlimentation
  );
  const { loading } = useSelector((state) => state.purchaseAlimentation);
  const { shops } = useSelector((state) => state.shop);
  const navigate = useNavigate();

  const [purchase, setPurchase] = useState({
    shop:
      user?.role === "superadmin"
        ? ""
        : typeof user?.shop === "object"
          ? user.shop._id
          : user.shop,
    client_name: "",
    client_phone: "",
    client_address: "",
    products: [
      {
        product: "",
        name: "",
        category: "",
        price_achat: 0,
        quantity: 1,
        price: 0,
      },
    ],
    total_amount: 0,
    payment_method: "cash",
    amount_paid: 0,
  });

  const [clientsWithDebt, setClientsWithDebt] = useState([]);
  const [isNewClient, setIsNewClient] = useState(true);

  useEffect(() => {
    if (user?.role === "superadmin") {
      dispatch(fetchAllShops());
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (user?.role === "superadmin" && purchase.shop) {
      dispatch(fetchProductsAlimentation(purchase.shop));
    } else if (user?.shop) {
      dispatch(fetchProductsAlimentation(user.shop._id));
    }
  }, [dispatch, user, purchase.shop]);

  useEffect(() => {
    const loadClientsWithDebt = async () => {
      try {
        const shopId =
          user?.role === "superadmin"
            ? purchase.shop
            : typeof user?.shop === "object"
              ? user.shop._id
              : user.shop;

        if (shopId) {
          const clients = await dispatch(fetchClientsWithDebt(shopId));
          setClientsWithDebt(clients || []);
        }
      } catch (error) {
        console.error("Error loading clients:", error);
        toast.error("Erreur lors du chargement des clients avec dette");
        setClientsWithDebt([]);
      }
    };

    loadClientsWithDebt();
  }, [dispatch, user, purchase.shop]);

  const calculateTotal = (products) => {
    return products.reduce((total, prod) => {
      const quantity = parseFloat(prod.quantity) || 0;
      const price = parseFloat(prod.price) || 0;
      return total + quantity * price; // Ensures proper calculations
    }, 0);
  };

  const handleProductChange = (index, field, value) => {
    const updatedProducts = [...purchase.products];

    if (field === "product") {
      const selectedProduct = productsAlimentation.find((p) => p._id === value);
      if (selectedProduct) {
        updatedProducts[index] = {
          ...updatedProducts[index],
          product: selectedProduct._id,
          name: selectedProduct.name,
          price_achat: selectedProduct.price_achat,
          category: selectedProduct.category,
        };
      }
    } else {
      updatedProducts[index][field] = value; // Update the field directly
    }

    const total_amount = calculateTotal(updatedProducts);
    setPurchase({ ...purchase, products: updatedProducts, total_amount });
  };

  const addProduct = () => {
    setPurchase({
      ...purchase,
      products: [
        ...purchase.products,
        { product: "", name: "", category: "", price_achat: 0, quantity: 1, price: 0 },
      ],
    });
  };

  const removeProduct = (index) => {
    const updatedProducts = purchase.products.filter((_, i) => i !== index);
    const total_amount = calculateTotal(updatedProducts);
    setPurchase({ ...purchase, products: updatedProducts, total_amount });
  };

  const validateForm = () => {
    if (user?.role === "superadmin" && !purchase.shop) {
      toast.error("Veuillez sélectionner un shop");
      return false;
    }

    if (!purchase.client_name.trim()) {
      toast.error("Nom du client est requis");
      return false;
    }

    if (!purchase.client_phone.trim()) {
      toast.error("Téléphone du client est requis");
      return false;
    }

    if (purchase.products.length === 0) {
      toast.error("Veuillez ajouter au moins un produit");
      return false;
    }

    for (const product of purchase.products) {
      if (!product.product) {
        toast.error("Veuillez sélectionner un produit pour tous les éléments");
        return false;
      }
      if (!product.quantity || product.quantity <= 0) {
        toast.error("La quantité doit être supérieure à 0 pour tous les produits");
        return false;
      }
    }

    if (purchase.payment_method === "debt") {
      const existingClient = clientsWithDebt.find(
        (c) => c._id.client_phone === purchase.client_phone
      );

      if (existingClient && existingClient.debtCount >= 2) {
        toast.error("Le client a déjà atteint la limite de 2 dettes");
        return false;
      }
    } else {
      if (purchase.amount_paid !== purchase.total_amount) {
        toast.error(
          "Le montant payé doit être égal au montant total pour les paiements en espèces"
        );
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      const purchaseData = {
        ...purchase,
        shop_id:
          user?.role === "superadmin"
            ? purchase.shop
            : typeof user?.shop === "object"
              ? user.shop._id
              : user.shop,
        products: purchase.products.map(
          ({ product, name, category, price_achat, quantity, price }) => ({
            product,
            name,
            category,
            price_achat,
            quantity,
            price,
          })
        ),
        total_amount: purchase.total_amount,
        amount_paid: purchase.amount_paid || 0,
        remaining_amount: purchase.total_amount - (purchase.amount_paid || 0),
      };

      const result = await dispatch(createPurchaseAlimentation(purchaseData));

      if (result.success) {
        toast.success("Vente créée avec succès");
        navigate("/transaction-alimentation/history");
        setPurchase({
          shop:
            user?.role === "superadmin"
              ? ""
              : typeof user?.shop === "object"
                ? user.shop._id
                : user.shop,
          client_name: "",
          client_phone: "",
          client_address: "",
          products: [
            { product: "", name: "", category: "", price_achat: 0, quantity: 1, price: 0 },
          ],
          total_amount: 0,
          payment_method: "cash",
          amount_paid: 0,
        });
        setIsNewClient(true);
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message || "Erreur lors de la création de l'achat"
      );
    }
  };

  return (
    <Container>
      <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
        <Typography variant="h4" gutterBottom>
          Vente des produits alimentaires
        </Typography>

        <Box component="form" onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            {user?.role === "superadmin" && (
              <Grid item xs={12} md={4}>
                <FormControl fullWidth required>
                  <InputLabel>Sélectionner un Shop</InputLabel>
                  <Select
                    value={purchase.shop}
                    onChange={(e) =>
                      setPurchase({ ...purchase, shop: e.target.value })
                    }
                    label="Sélectionner un Shop"
                  >
                    {shops.map((shop) => (
                      <MenuItem key={shop._id} value={shop._id}>
                        {shop.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}

            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <InputLabel>Mode de paiement*</InputLabel>
                <Select
                  value={purchase.payment_method}
                  onChange={(e) =>
                    setPurchase({ ...purchase, payment_method: e.target.value })
                  }
                  label="Payment Method"
                  required
                >
                  <MenuItem value="cash">Cash</MenuItem>
                  <MenuItem value="debt">Credit</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                label="Nom du client*"
                value={purchase.client_name}
                onChange={(e) =>
                  setPurchase({ ...purchase, client_name: e.target.value })
                }
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label="Téléphone du client*"
                value={purchase.client_phone}
                onChange={(e) =>
                  setPurchase({ ...purchase, client_phone: e.target.value })
                }
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label="Adresse du client"
                value={purchase.client_address}
                onChange={(e) =>
                  setPurchase({ ...purchase, client_address: e.target.value })
                }
                fullWidth
              />
            </Grid>
          </Grid>

          {purchase.products.map((prod, index) => (
            <Paper key={index} elevation={2} sx={{ p: 2, mt: 2 }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={3}>
                  <Autocomplete
                    value={
                      productsAlimentation.find((p) => p._id === prod.product) ||
                      null
                    }
                    onChange={(e, newValue) => {
                      if (newValue) {
                        handleProductChange(index, "product", newValue._id);
                      }
                    }}
                    options={productsAlimentation}
                    getOptionLabel={(option) => option.name || ""}
                    renderInput={(params) => (
                      <TextField {...params} label="Produit*" fullWidth />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <TextField
                    label="Catégorie"
                    value={prod.category}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <TextField
                    label="Prix d'achat"
                    value={prod.price_achat?.toLocaleString()}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <TextField
                    label="Prix"
                    value={prod.price}
                    onChange={(e) =>
                      handleProductChange(index, "price", Number(e.target.value))
                    }
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <TextField
                    type="number"
                    label="Quantité"
                    value={prod.quantity}
                    onChange={(e) =>
                      handleProductChange(index, "quantity", parseInt(e.target.value))
                    }
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <TextField
                    label="Total"
                    value={(prod.price || 0) * (prod.quantity || 0)}
                    disabled
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={1}>
                  <IconButton onClick={() => removeProduct(index)} color="error">
                    <RemoveCircleOutline />
                  </IconButton>
                </Grid>
              </Grid>
            </Paper>
          ))}

          <Button
            startIcon={<AddCircleOutline />}
            onClick={addProduct}
            sx={{ mt: 2 }}
            color="secondary"
          >
            Ajouter un produit
          </Button>

          <Grid container spacing={2} sx={{ mt: 3 }}>
            <Grid item xs={12} md={4}>
              <TextField
                label="Montant payé"
                type="number"
                value={purchase.amount_paid}
                onChange={(e) =>
                  setPurchase({
                    ...purchase,
                    amount_paid: parseFloat(e.target.value),
                  })
                }
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper elevation={2} sx={{ p: 2, textAlign: "right" }}>
                <Typography variant="h6">
                  Montant total: {purchase.total_amount || 0} Fbu
                </Typography>
              </Paper>
            </Grid>
          </Grid>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            size="large"
            sx={{ mt: 4 }}
            disabled={loading}
          >
            {loading ? "Vendre..." : "Vendre"}
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default VenteAlimentation;
