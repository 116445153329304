import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  purchases: [],
  cashPurchases: [],
  creditPurchases: [],
  dailyPurchasePriceAchat: 0,
  monthlyPurchasePriceAchat: 0,
  dailyPurchaseSales: 0,
  monthlyPurchaseSales: 0,
  loading: false,
  error: null,
};

export const purchaseAlimentationReducer = createReducer(initialState, {
  "CREATE_PURCHASE_ALIMENTATION_REQUEST": (state) => {
    state.loading = true;
    state.error = null;
  },
  "CREATE_PURCHASE_ALIMENTATION_SUCCESS": (state, action) => {
    state.loading = false;
    state.purchases.push(action.payload);
    state.error = null;
  },
  "CREATE_PURCHASE_ALIMENTATION_FAILURE": (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },

  "FETCH_CASH_PURCHASES_REQUEST": (state) => {
    state.loading = true;
    state.error = null;
  },
  "FETCH_CASH_PURCHASES_SUCCESS": (state, action) => {
    state.loading = false;
    state.cashPurchases = action.payload;
    state.error = null;
  },
  "FETCH_CASH_PURCHASES_FAILURE": (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },

  "FETCH_CREDIT_PURCHASES_REQUEST": (state) => {
    state.loading = true;
    state.error = null;
  },
  "FETCH_CREDIT_PURCHASES_SUCCESS": (state, action) => {
    state.loading = false;
    state.creditPurchases = action.payload;
    state.error = null;
  },
  "FETCH_CREDIT_PURCHASES_FAILURE": (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },

  "PAY_REMAINING_PURCHASE_REQUEST": (state) => {
    state.loading = true;
    state.error = null;
  },
  "PAY_REMAINING_PURCHASE_SUCCESS": (state, action) => {
    state.loading = false;
    state.error = null;
  },
  "PAY_REMAINING_PURCHASE_FAILURE": (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },

  "DELETE_PURCHASE_REQUEST": (state) => {
    state.loading = true;
    state.error = null;
  },
  "DELETE_PURCHASE_SUCCESS": (state, action) => {
    state.loading = false;
    state.cashPurchases = state.cashPurchases.filter(purchase => purchase._id !== action.payload);
    state.creditPurchases = state.creditPurchases.filter(purchase => purchase._id !== action.payload);
    state.error = null;
  },
  "DELETE_PURCHASE_FAILURE": (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },
  "FETCH_CLIENTS_WITH_DEBT_REQUEST": (state) => {
    state.loading = true;
    state.error = null;
  },
  "FETCH_CLIENTS_WITH_DEBT_SUCCESS": (state, action) => {
    state.loading = false;
    state.clientsWithDebt = action.payload;
    state.error = null;
  },
  "FETCH_CLIENTS_WITH_DEBT_FAILURE": (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.clientsWithDebt = [];
  },
"FETCH_DAILY_PURCHASE_PRICE_ACHAT_REQUEST": (state) => {
    state.loading = true;
    state.error = null;
  },
  "FETCH_DAILY_PURCHASE_PRICE_ACHAT_SUCCESS": (state, action) => {
    state.loading = false;
    state.dailyPurchasePriceAchat = action.payload;
    state.error = null;
  },
  "FETCH_DAILY_PURCHASE_PRICE_ACHAT_FAILURE": (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },

  //Fetching sales per day
  "FETCH_DAILY_PURCHASE_SALES_REQUEST": (state) => {
    state.loading = true;
    state.error = null;
  },
  "FETCH_DAILY_PURCHASE_SALES_SUCCESS": (state, action) => {
    state.loading = false;
    state.dailyPurchaseSales = action.payload;
    state.error = null;
  },
  "FETCH_DAILY_PURCHASE_SALES_FAILURE": (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },


  "FETCH_MONTHLY_PURCHASE_PRICE_ACHAT_REQUEST": (state) => {
    state.loading = true;
    state.error = null;
  },
  "FETCH_MONTHLY_PURCHASE_PRICE_ACHAT_SUCCESS": (state, action) => {
    state.loading = false;
    state.monthlyPurchasePriceAchat = action.payload;
    state.error = null;
  },
  "FETCH_MONTHLY_PURCHASE_PRICE_ACHAT_FAILURE": (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },

  //sales monthly
  "FETCH_MONTHLY_PURCHASE_SALES_REQUEST": (state) => {
    state.loading = true;
    state.error = null;
  },
  "FETCH_MONTHLY_PURCHASE_SALES_SUCCESS": (state, action) => {
    state.loading = false;
    state.monthlyPurchaseSales = action.payload;
    state.error = null;
  },
  "FETCH_MONTHLY_PURCHASE_SALES_FAILURE": (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },
});