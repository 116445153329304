import jsPDF from 'jspdf';
import moment from 'moment';
import 'jspdf-autotable';

export const generateDailySalesReport = (cashSales, bankSales, creditSales, shopName, dailyStats) => {
  const doc = new jsPDF();
  const pageWidth = doc.internal.pageSize.width;
  let yPos = 20;
  const lineHeight = 10;
  const today = moment().format('DD/MM/YYYY');

  // Header
  doc.setFontSize(16);
  doc.text('Rapport de ventes journalieres', pageWidth / 2, yPos, { align: 'center' });
  yPos += lineHeight;

  doc.setFontSize(12);
  doc.text(`Shop: ${shopName}`, pageWidth / 2, yPos, { align: 'center' });
  yPos += lineHeight;

  doc.text(`Date: ${today}`, pageWidth / 2, yPos, { align: 'center' });
  yPos += lineHeight * 2;

  // Summary calculations
  const cashTotal = cashSales.reduce((sum, sale) => sum + sale.total_amount, 0);
  const bankTotal = bankSales.reduce((sum, sale) => sum + sale.total_amount, 0);
  const creditTotal = creditSales.reduce((sum, sale) => sum + sale.total_amount, 0);
  const grandTotal = cashTotal + bankTotal + creditTotal;

  // Sales Summary
  doc.setFontSize(14);
  doc.text('Résumé des ventes', 20, yPos);
  yPos += lineHeight;

  doc.setFontSize(12);
  doc.text(`Ventes en Cash: ${cashTotal.toLocaleString()} Fbu (${cashSales.length} transactions)`, 20, yPos);
  yPos += lineHeight;
  doc.text(`Ventes bancaires: ${bankTotal.toLocaleString()} Fbu (${bankSales.length} transactions)`, 20, yPos);
  yPos += lineHeight;
  doc.text(`Ventes à crédit: ${creditTotal.toLocaleString()} Fbu (${creditSales.length} transactions)`, 20, yPos);
  yPos += lineHeight * 1.5;

  doc.setFont(undefined, 'bold');
  doc.text(`Ventes totales: ${grandTotal.toLocaleString()} Fbu`, 20, yPos);
  doc.setFont(undefined, 'normal');
  yPos += lineHeight * 2;

  // Add Daily Interest Section
  doc.setFontSize(14);
  doc.text('Résumé des intérêts journaliers', 20, yPos);
  yPos += lineHeight;

  doc.setFontSize(12);
  const dailyInterest =
    dailyStats?.type === 'electronics'
      ? dailyStats.dailySales - dailyStats.dailyPriceAchat - dailyStats.dailyDepenses
      : dailyStats.dailyPurchaseSales - dailyStats.dailyPurchasePriceAchat - dailyStats.dailyDepenses;
  doc.text(`Intérêt d'aujourd'hui: ${dailyInterest.toLocaleString()} Fbu`, 20, yPos);
  yPos += lineHeight * 2;

  // Detailed Sales Transactions
  const addSalesSection = (title, sales) => {
    if (sales.length > 0) {
      doc.setFontSize(14);
      doc.text(title, 20, yPos);
      yPos += lineHeight;

      sales.forEach(sale => {
        if (yPos > 250) {
          doc.addPage();
          yPos = 20;
        }

        doc.setFontSize(12);
        doc.text(`Time: ${moment(sale.createdAt).format('HH:mm')} - Client: ${sale.client_name || 'N/A'}`, 20, yPos);
        yPos += lineHeight;

        // Products table for each sale
        const saleProducts = sale.products.map(product => [
          product.name,
          product.quantity.toString(),
          product.price.toLocaleString() + ' Fbu',
          (product.price * product.quantity).toLocaleString() + ' Fbu'
        ]);

        doc.autoTable({
          startY: yPos,
          head: [['Produit', 'Quantité', 'Prix Unitaire', 'Total']],
          body: saleProducts,
          theme: 'grid',
          headStyles: { fillColor: [100, 100, 100] },
          styles: { fontSize: 8 },
          margin: { left: 30 }
        });

        yPos = doc.lastAutoTable.finalY + lineHeight;
        doc.text(`Total: ${sale.total_amount.toLocaleString()} Fbu`, 30, yPos);
        yPos += lineHeight * 1.5;
      });
    }
  };

  addSalesSection('Transactions en Cash', cashSales);
  addSalesSection('Transactions bancaires', bankSales);
  addSalesSection('Transactions à crédit', creditSales);

  // Save the PDF
  doc.save(`rapport-journalier-des-ventes-${today}.pdf`);
};

export const generateMonthlySalesReport = (cashSales, bankSales, creditSales, shopName, monthlyStats, capitals) => {
  const doc = new jsPDF();
  const pageWidth = doc.internal.pageSize.width;
  let yPos = 20;
  const lineHeight = 10;
  const currentMonth = moment().format('MMMM YYYY');

  // Header
  doc.setFontSize(16);
  doc.text('Rapport mensuel des ventes', pageWidth / 2, yPos, { align: 'center' });
  yPos += lineHeight;

  doc.setFontSize(12);
  doc.text(`Shop: ${shopName}`, pageWidth / 2, yPos, { align: 'center' });
  yPos += lineHeight;

  doc.text(`Mois: ${currentMonth}`, pageWidth / 2, yPos, { align: 'center' });
  yPos += lineHeight * 2;

  // Add Monthly Interest Section
  doc.setFontSize(14);
  doc.text('Résumé des intérêts mensuels', 20, yPos);
  yPos += lineHeight;

  doc.setFontSize(12);
  const monthlyInterest =
    monthlyStats?.type === 'electronics'
      ? monthlyStats.monthlySales - monthlyStats.monthlyPriceAchat - monthlyStats.monthlyDepenses
      : monthlyStats.monthlyPurchaseSales - monthlyStats.monthlyPurchasePriceAchat - monthlyStats.monthlyDepenses;
  doc.text(`Intérêt mensuel: ${monthlyInterest.toLocaleString()} Fbu`, 20, yPos);
  yPos += lineHeight * 2;

  // Add Capital Summary Section
  doc.setFontSize(14);
  doc.text('Résumé des capitaux', 20, yPos);
  yPos += lineHeight;

  doc.setFontSize(12);
  doc.text(`Capital Shop 1: ${capitals.totalElectronicsFirstShop.toLocaleString()} Fbu`, 20, yPos);
  yPos += lineHeight;
  doc.text(`Capital Shop 2: ${capitals.totalElectronicsSecondShop.toLocaleString()} Fbu`, 20, yPos);
  yPos += lineHeight;
  doc.text(`Capital Shop 3: ${capitals.totalAlimentation.toLocaleString()} Fbu`, 20, yPos);
  yPos += lineHeight;

  const totalCapital =
    capitals.totalAlimentation + capitals.totalElectronicsFirstShop + capitals.totalElectronicsSecondShop;
  doc.text(`Capital Total: ${totalCapital.toLocaleString()} Fbu`, 20, yPos);
  yPos += lineHeight * 2;

  // Monthly Sales Summary
  const cashTotal = cashSales.reduce((sum, sale) => sum + sale.total_amount, 0);
  const bankTotal = bankSales.reduce((sum, sale) => sum + sale.total_amount, 0);
  const creditTotal = creditSales.reduce((sum, sale) => sum + sale.total_amount, 0);
  const grandTotal = cashTotal + bankTotal + creditTotal;

  doc.setFontSize(14);
  doc.text('Résumé des ventes mensuelles', 20, yPos);
  yPos += lineHeight;

  doc.setFontSize(12);
  doc.text(`Ventes en Cash: ${cashTotal.toLocaleString()} Fbu (${cashSales.length} transactions)`, 20, yPos);
  yPos += lineHeight;
  doc.text(`Ventes bancaires: ${bankTotal.toLocaleString()} Fbu (${bankSales.length} transactions)`, 20, yPos);
  yPos += lineHeight;
  doc.text(`Ventes à crédit: ${creditTotal.toLocaleString()} Fbu (${creditSales.length} transactions)`, 20, yPos);
  yPos += lineHeight * 1.5;

  doc.setFont(undefined, 'bold');
  doc.text(`Ventes totales: ${grandTotal.toLocaleString()} Fbu`, 20, yPos);
  doc.setFont(undefined, 'normal');
  yPos += lineHeight * 2;

  // Product Summary Table
  const productSummary = new Map();

  const addToProductSummary = (products) => {
    products.forEach(product => {
      const key = product.name;
      if (!productSummary.has(key)) {
        productSummary.set(key, {
          quantity: 0,
          totalAmount: 0,
          category: product.category,
        });
      }
      const summary = productSummary.get(key);
      summary.quantity += product.quantity;
      summary.totalAmount += product.price * product.quantity;
    });
  };

  [...cashSales, ...bankSales, ...creditSales].forEach(sale => {
    addToProductSummary(sale.products);
  });

  doc.setFontSize(14);
  doc.text('Résumé mensuel des produits', 20, yPos);
  yPos += lineHeight;

  const productTableData = Array.from(productSummary.entries()).map(([name, data]) => [
    name,
    data.category,
    data.quantity.toString(),
    data.totalAmount.toLocaleString() + ' Fbu',
  ]);

  doc.autoTable({
    startY: yPos,
    head: [['Produit', 'Categorie', 'Quantité vendue', 'Montant total']],
    body: productTableData,
    theme: 'grid',
    headStyles: { fillColor: [71, 71, 71] },
    styles: { fontSize: 10 },
  });

  yPos = doc.lastAutoTable.finalY + lineHeight * 2;

  // Daily Breakdown Section
  doc.setFontSize(14);
  doc.text('Répartition des ventes journalieres', 20, yPos);
  yPos += lineHeight;

  const dailyTotals = new Map();

  const addToDaily = (sale) => {
    const date = moment(sale.createdAt).format('DD/MM/YYYY');
    if (!dailyTotals.has(date)) {
      dailyTotals.set(date, {
        cash: 0,
        bank: 0,
        credit: 0,
        products: new Map(),
      });
    }
    const dayData = dailyTotals.get(date);
    const method = sale.payment_method || 'cash'; // Default to 'cash' if no method
    dayData[method] += sale.total_amount;

    sale.products.forEach(product => {
      const productKey = product.name;
      if (!dayData.products.has(productKey)) {
        dayData.products.set(productKey, {
          quantity: 0,
          amount: 0,
        });
      }
      const productData = dayData.products.get(productKey);
      productData.quantity += product.quantity;
      productData.amount += product.price * product.quantity;
    });
  };

  cashSales.forEach(sale => addToDaily(sale));
  bankSales.forEach(sale => addToDaily(sale));
  creditSales.forEach(sale => addToDaily(sale));

  Array.from(dailyTotals.entries())
    .sort((a, b) => moment(a[0], 'DD/MM/YYYY').diff(moment(b[0], 'DD/MM/YYYY')))
    .forEach(([date, data]) => {
      if (yPos > 250) {
        doc.addPage();
        yPos = 20;
      }

      doc.setFontSize(12);
      doc.setFont(undefined, 'bold');
      doc.text(date, 20, yPos);
      yPos += lineHeight;

      doc.setFont(undefined, 'normal');
      doc.setFontSize(10);
      const dayTotal = data.cash + data.bank + data.credit;
      doc.text(`Cash: ${data.cash.toLocaleString()} Fbu`, 30, yPos);
      doc.text(`Bank: ${data.bank.toLocaleString()} Fbu`, 90, yPos);
      doc.text(`Credit: ${data.credit.toLocaleString()} Fbu`, 150, yPos);
      yPos += lineHeight;
      doc.text(`Total: ${dayTotal.toLocaleString()} Fbu`, 30, yPos);
      yPos += lineHeight;

      const dailyProductData = Array.from(data.products.entries()).map(([name, pData]) => [
        name,
        pData.quantity.toString(),
        pData.amount.toLocaleString() + ' Fbu',
      ]);

      if (dailyProductData.length > 0) {
        doc.autoTable({
          startY: yPos,
          head: [['Produit', 'Quantité', 'Montant']],
          body: dailyProductData,
          theme: 'grid',
          headStyles: { fillColor: [100, 100, 100] },
          styles: { fontSize: 8 },
          margin: { left: 30 },
        });

        yPos = doc.lastAutoTable.finalY + lineHeight;
      }
    });

  // Footer
  doc.setFontSize(8);
  doc.setTextColor(128, 128, 128);
  doc.text('Système développé par hyphetech.tech', pageWidth / 2, doc.internal.pageSize.height - 10, { align: 'center' });

  // Save the PDF
  doc.save(`rapport-mensuel-des-ventes-${currentMonth}.pdf`);
};
