import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import AccessDenied from './AccessDenied';

const RoleProtectedRoute = ({ children, requiredRoles, checkAccess }) => {
  const { user, isAuthenticated } = useSelector((state) => state.user);

  if (!isAuthenticated) {
    // Redirect unauthenticated users to the login page
    return <Navigate to="/login" replace />;
  }

  // Check if the user's role is in the list of required roles
  if (requiredRoles && !requiredRoles.includes(user.role)) {
    return <AccessDenied />;
  }

  // If a custom access check is provided, use it
  if (typeof checkAccess === 'function' && !checkAccess(user)) {
    return <AccessDenied />;
  }

  // If all checks pass, render the children components
  return children;
};

export default RoleProtectedRoute;
