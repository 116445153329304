import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  products: [],
  achatProducts: [],
  loading: false,
  error: null,
};

export const productReducer = createReducer(initialState, (builder) => {
  builder
    .addCase("FetchProductsRequest", (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase("FetchProductsSuccess", (state, action) => {
      state.loading = false;
      state.products = action.payload;
      state.error = null;
    })
    .addCase("FetchProductsFailure", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("FetchAllProductsRequest", (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase("FetchAllProductsSuccess", (state, action) => {
      state.loading = false;
      state.products = action.payload;
      state.error = null;
    })
    .addCase("FetchAllProductsFailure", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("CreateProductRequest", (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase("CreateProductSuccess", (state, action) => {
      state.loading = false;
      state.products.push(action.payload);
      state.error = null;
    })
    .addCase("CreateProductFailure", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("UpdateProductRequest", (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase("UpdateProductSuccess", (state, action) => {
      state.loading = false;
      const index = state.products.findIndex(product => product._id === action.payload._id);
      if (index !== -1) {
        state.products[index] = action.payload;
      }
      state.error = null;
    })
    .addCase("UpdateProductFailure", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("DeleteProductRequest", (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase("DeleteProductSuccess", (state, action) => {
      state.loading = false;
      state.products = state.products.filter(product => product._id !== action.payload);
      state.error = null;
    })
    .addCase("DeleteProductFailure", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    // Achat Product reducers
    .addCase("CreateAchatProductRequest", (state) => {
      state.loading = true;
    })
    .addCase("CreateAchatProductSuccess", (state, action) => {
      state.loading = false;
      state.achatProducts.push(action.payload);
      state.error = null;
    })
    .addCase("CreateAchatProductFailure", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("FetchAchatProductsRequest", (state) => {
      state.loading = true;
    })
    .addCase("FetchAchatProductsSuccess", (state, action) => {
      state.loading = false;
      state.achatProducts = action.payload;
      state.error = null;
    })
    .addCase("FetchAchatProductsFailure", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("UpdateAchatProductRequest", (state) => {
      state.loading = true;
    })
    .addCase("UpdateAchatProductSuccess", (state, action) => {
      state.loading = false;
      const index = state.achatProducts.findIndex(ap => ap._id === action.payload._id);
      if (index !== -1) {
        state.achatProducts[index] = action.payload;
      }
      state.error = null;
    })
    .addCase("UpdateAchatProductFailure", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("DeleteAchatProductRequest", (state) => {
      state.loading = true;
    })
    .addCase("DeleteAchatProductSuccess", (state, action) => {
      state.loading = false;
      state.achatProducts = state.achatProducts.filter(ap => ap._id !== action.payload);
      state.error = null;
    })
    .addCase("DeleteAchatProductFailure", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
});
