import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Tooltip,
  Chip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Card,
  CardContent,
  Grid,
} from "@mui/material";
import {
  History as HistoryIcon,
  CheckCircle,
  Visibility as VisibilityIcon,
  Print as PrintIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { deleteSale, fetchCashSales } from "../../redux/actions/sales";
import { toast } from "react-toastify";
import moment from "moment";
import { fetchAllShops, selectShop } from "../../redux/actions/shop";
import ProductDetailsDialog from '../../components/ProductDetailsDialog';
import { generateReceipt } from '../../utils/receiptGenerator';
import { useInvoiceNumber } from '../../hooks/useInvoiceNumber';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const VenteEnCash = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { cashSales, loading } = useSelector((state) => state.sales);
  const { shops, selectedShop } = useSelector((state) => state.shop);

  const [openHistory, setOpenHistory] = useState(false);
  const [selectedSale, setSelectedSale] = useState(null);
  const [openProductDetails, setOpenProductDetails] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const { getInvoiceNumber } = useInvoiceNumber();

  // Define electronicShops by filtering only electronics-type shops from the available shops
  const electronicShops = shops.filter(
    (shop) => shop.enum === 'electronics' || shop.type === 'electronics'
  );

  useEffect(() => {
    const loadInitialData = async () => {
      try {
        if (user?.role === 'superadmin') {
          await dispatch(fetchAllShops());
          const firstShop = electronicShops[0];
          if (firstShop) {
            dispatch(selectShop(firstShop));
            await dispatch(fetchCashSales(firstShop._id));
          }
        } else if (user?.shop) {
          const shopId = typeof user.shop === 'object' ? user.shop._id : user.shop;
          await dispatch(fetchCashSales(shopId));
        }
      } catch (error) {
        console.error('Error loading cash sales:', error);
        toast.error('Failed to load sales');
      }
    };

    loadInitialData();
  }, [dispatch, user, shops.length]);

  const handleShopChange = (event) => {
    const shopId = event.target.value;
    if (shopId === 'all') {
      dispatch(selectShop(null));
    } else {
      const shop = shops.find(s => s._id === shopId);
      dispatch(selectShop(shop));
    }
    dispatch(fetchCashSales(shopId === 'all' ? null : shopId));
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filterSalesByDate = (sales) => {
    return sales.filter((sale) => {
      if (!startDate && !endDate) return true;

      const saleDate = moment(sale.createdAt).startOf('day');

      if (startDate && !endDate) {
        return saleDate.isSameOrAfter(moment(startDate).startOf('day'));
      }

      if (!startDate && endDate) {
        return saleDate.isSameOrBefore(moment(endDate).startOf('day'));
      }

      return saleDate.isBetween(
        moment(startDate).startOf('day'),
        moment(endDate).startOf('day'),
        'day',
        '[]'
      );
    });
  };

  const filterSales = (sales) => {
    const filteredBySearch = sales.filter((sale) =>
      sale.client_name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    return filterSalesByDate(filteredBySearch);
  };

  const handlePrint = async (sale) => {
    try {
      const shopId = sale.shop?._id || sale.shop;
      const invoiceNumber = await getInvoiceNumber(shopId);

      const doc = await generateReceipt(sale, invoiceNumber);
      doc.save(`facture-${invoiceNumber}.pdf`);
    } catch (error) {
      toast.error("Erreur lors de l'impression de la facture");
      console.error('Print error:', error);
    }
  };

  const handleDelete = async (saleId) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer cette vente ?")) {
      try {
        await dispatch(deleteSale(saleId));
        toast.success("Vente supprimée avec succès");
        // Refresh the sales list
        if (user?.role === 'superadmin') {
          dispatch(fetchCashSales(selectedShop._id));
        } else {
          dispatch(fetchCashSales(user.shop._id));
        }
      } catch (error) {
        toast.error(error.message || "Erreur lors de la suppression");
      }
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" m="20px">
        <Typography>Loading sales...</Typography>
      </Box>
    );
  }
  const calculateTotal = () => {
    const filteredSales = filterSales(cashSales);
    return filteredSales.reduce((total, sale) => total + sale.total_amount, 0) || 0;
  };

  return (
    <Box m="20px">
      {/* Summary Card */}
      <Card sx={{ mb: 4, mt: 2 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Total des ventes en cash
          </Typography>
          <Typography variant="h4">
            {calculateTotal().toLocaleString()} Fbu
          </Typography>
        </CardContent>
      </Card>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4">
          Ventes payées ({cashSales?.length || 0})
        </Typography>

        {user.role === 'superadmin' && (
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel>Shop</InputLabel>
            <Select
              value={selectedShop ? selectedShop._id : 'all'}
              onChange={handleShopChange}
              label="Shop"
            >
              <MenuItem value="all">All Shops</MenuItem>
              {electronicShops.map((shop) => (
                <MenuItem key={shop._id} value={shop._id}>{shop.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Box>

      <Box display="flex" gap={2} mb={2}>
        <TextField
          placeholder="Recherche par client"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={handleSearchChange}
          sx={{ flexGrow: 1 }}
        />

        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label="Date de début"
            value={startDate}
            onChange={(newValue) => setStartDate(newValue)}
            slotProps={{ textField: { size: 'small' } }}
          />
          <DatePicker
            label="Date de fin"
            value={endDate}
            onChange={(newValue) => setEndDate(newValue)}
            slotProps={{ textField: { size: 'small' } }}
          />
        </LocalizationProvider>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Client</TableCell>
              <TableCell>Montant Total</TableCell>
              <TableCell>Montant payé</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
              <TableCell>Effectue par</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filterSales(cashSales).length > 0 ? (
              filterSales(cashSales).map((sale) => (
                <TableRow key={sale._id}>
                  <TableCell>
                    {moment(sale.createdAt).format("DD/MM/YYYY HH:mm")}
                  </TableCell>
                  <TableCell>{sale.client_name}</TableCell>
                  <TableCell>{sale.total_amount?.toLocaleString()} Fbu</TableCell>
                  <TableCell>{sale.amount_paid?.toLocaleString()} Fbu</TableCell>
                  <TableCell>
                    <Chip
                      icon={<CheckCircle />}
                      label="Paid"
                      color="success"
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    <Box display="flex" gap={1}>
                      <Tooltip title="Payment History">
                        <IconButton
                          onClick={() => {
                            setSelectedSale(sale);
                            setOpenHistory(true);
                          }}
                        >
                          <HistoryIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="View Details">
                        <IconButton
                          onClick={() => {
                            setSelectedSale(sale);
                            setOpenProductDetails(true);
                          }}
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Imprimer Facture">
                        <IconButton onClick={() => handlePrint(sale)}>
                          <PrintIcon />
                        </IconButton>
                      </Tooltip>
                      {user.role === 'superadmin' && (
                        <Tooltip title="Supprimer">
                          <IconButton
                            onClick={() => handleDelete(sale._id)}
                            color="error"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>
                  </TableCell>
                  <TableCell>{sale.user?.name}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  No matching sales found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={openHistory}
        onClose={() => setOpenHistory(false)}
        maxWidth="md"
      >
        <DialogTitle>Payment History</DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Montant</TableCell>
                  <TableCell>Methode</TableCell>
                  <TableCell>Processed By</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedSale?.payment_history?.map((payment, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {moment(payment.payment_date).format("DD/MM/YYYY HH:mm")}
                    </TableCell>
                    <TableCell>{payment.amount?.toLocaleString()} Fbu</TableCell>
                    <TableCell>
                      {payment.payment_method === 'debt'
                        ? 'Avance avec dette'
                        : payment.payment_method}
                    </TableCell>
                    <TableCell>{payment.user?.name || 'N/A'}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>

      <ProductDetailsDialog
        open={openProductDetails}
        onClose={() => {
          setOpenProductDetails(false);
          setSelectedSale(null);
        }}
        products={selectedSale?.products || []}
      />
    </Box>
  );
};

export default VenteEnCash;
