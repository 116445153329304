import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  productsAlimentation: [],
  achatProductsAlimentation: [],
  loading: false,
  error: null,
};

export const productAlimentationReducer = createReducer(initialState, (builder) => {
  builder
    .addCase("FetchProductsAlimentationRequest", (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase("FetchProductsAlimentationSuccess", (state, action) => {
      state.loading = false;
      state.productsAlimentation = action.payload;
      state.error = null;
    })
    .addCase("FetchProductsAlimentationFailure", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("FetchAllProductsAlimentationRequest", (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase("FetchAllProductsAlimentationSuccess", (state, action) => {
      state.loading = false;
      state.productsAlimentation = action.payload;
      state.error = null;
    })
    .addCase("FetchAllProductsAlimentationFailure", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("CreateProductAlimentationRequest", (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase("CreateProductAlimentationSuccess", (state, action) => {
      state.loading = false;
      state.productsAlimentation.push(action.payload);
      state.error = null;
    })
    .addCase("CreateProductAlimentationFailure", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("UpdateProductAlimentationRequest", (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase("UpdateProductAlimentationSuccess", (state, action) => {
      state.loading = false;
      const index = state.productsAlimentation.findIndex(product => product._id === action.payload._id);
      if (index !== -1) {
        state.productsAlimentation[index] = action.payload;
      }
      state.error = null;
    })
    .addCase("UpdateProductAlimentationFailure", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("DeleteProductAlimentationRequest", (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase("DeleteProductAlimentationSuccess", (state, action) => {
      state.loading = false;
      state.productsAlimentation = state.productsAlimentation.filter(product => product._id !== action.payload);
      state.error = null;
    })
    .addCase("DeleteProductAlimentationFailure", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    // Achat Product Alimentation reducers
    .addCase("CreateAchatProductAlimentationRequest", (state) => {
      state.loading = true;
    })
    .addCase("CreateAchatProductAlimentationSuccess", (state, action) => {
      state.loading = false;
      state.achatProductsAlimentation.push(action.payload);
      state.error = null;
    })
    .addCase("CreateAchatProductAlimentationFailure", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("FetchAchatProductsAlimentationRequest", (state) => {
      state.loading = true;
    })
    .addCase("FetchAchatProductsAlimentationSuccess", (state, action) => {
      state.loading = false;
      state.achatProductsAlimentation = action.payload;
      state.error = null;
    })
    .addCase("FetchAchatProductsAlimentationFailure", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("UpdateAchatProductAlimentationRequest", (state) => {
      state.loading = true;
    })
    .addCase("UpdateAchatProductAlimentationSuccess", (state, action) => {
      state.loading = false;
      const index = state.achatProductsAlimentation.findIndex(ap => ap._id === action.payload._id);
      if (index !== -1) {
        state.achatProductsAlimentation[index] = action.payload;
      }
      state.error = null;
    })
    .addCase("UpdateAchatProductAlimentationFailure", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase("DeleteAchatProductAlimentationRequest", (state) => {
      state.loading = true;
    })
    .addCase("DeleteAchatProductAlimentationSuccess", (state, action) => {
      state.loading = false;
      state.achatProductsAlimentation = state.achatProductsAlimentation.filter(ap => ap._id !== action.payload);
      state.error = null;
    })
    .addCase("DeleteAchatProductAlimentationFailure", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
});
